import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appTerminalRules]'
})
export class TerminalDirective {

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return (event.charCode >= 65 && event.charCode <= 90) ||
           (event.charCode >= 97 && event.charCode <= 122) ||
           event.charCode === 32 ||
					 event.key === '-' ||
           event.key === '/';
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    let pastedText = event.clipboardData.getData('text');
    let regex = new RegExp(/[^A-Za-z\s]/g);

    if (regex.test(pastedText)) {
      event.preventDefault();
    }
  }

}