import {Component, OnInit, Input} from '@angular/core';
import {ObjectFilter} from '../../dto/object-filter.dto';
import {Labels} from 'src/internationalization/labels/labels';
import {Constants} from 'src/constants';
import {Colors} from 'src/colors';
import * as moment from 'moment';

@Component({
	selector: 'app-status-badge',
	templateUrl: './status-badge.component.html',
	styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent implements OnInit {
	@Input() status: any; // old Status
	@Input() isStatus: boolean;
	@Input() statusTooltip: string;
	@Input() globeStatus: ObjectFilter;
	@Input() globeExpiryDate: string;
	@Input() tooltipText: string;
	@Input() isTitlecase: boolean = true;

	public _constants = Constants.constant;
	public _labels = Labels.getLabels();
	public _colors = Colors.color;

	colorGreen = this._colors.colorGreen; // 'A' or Status: 'Active'
	colorGrey = this._colors.colorGrey; // 'I'
	colorYellow = this._colors.colorYellow; // Globe or Status: 'Pending'
	colorBlue = this._colors.colorBlue; // Status: 'Inactive'
	colorPurple = this._colors.colorPurple;
	colorRed = this._colors.colorRed;
	colorBlack = this._colors.colorBlack; // Status: 'Not Applied'

	constructor() {
	}

	ngOnInit() {
	}

	getStatusColor() {
		// this method will be changed on future. By now, it only define a status
		// color based on stateRegistration value. Late, it'll define the BgColor by
		// it's GlobeStatus and ExpiryDate - or will recieve it directly from back-end
		return (this.globeStatus && this.globeExpiryDate) ? this.getGlobeBpColor() : this.getDefaultStatusColor();
	}

	getGlobeBpColor() {
		const now = moment(moment().format(this._constants.dateFormat.YYYY_MM_DD_hyphen));
		const expiryDate = moment(moment(this.globeExpiryDate).format(this._constants.dateFormat.YYYY_MM_DD_hyphen));
		const description = this.globeStatus.description.trim().toUpperCase();

		if (expiryDate < now || description == this._constants.status.rejected.toUpperCase() || description == this._constants.status.cancelled.toUpperCase() || description == this._constants.status.inactive_long.toUpperCase()) {
			return this.colorGrey;
		}

		if (description === this._constants.status.approved.toUpperCase()) {
			return this.colorGreen;
		} else {
			return this.colorYellow;
		}
	}

	getDefaultStatusColor() {
		// below: only happens if it doesn't receive the real globalBp and globeExpiryDate values
		if (this.isStatus) {
			switch (this.status) {
				case this._constants.status.active: // Status: 'A'
					return this.colorGreen;
				case this._constants.status.active_long: // Status: 'Active'
				case this._constants.status.active_long.toUpperCase():
					return this.colorGreen;
				case this._constants.status.deactive: // Status: 'DeActive'
					return this.colorGrey;
				case this._constants.status.deleted: // Status: 'Deleted'
					return this.colorRed;
				case this._constants.status.pending: // Status: 'Pending'
					return this.colorYellow;
				case this._constants.status.not_applied: // Status: 'Not Applied'
					return this.colorPurple;
				case this._constants.status.true: // Status: 'True'
					return this.colorGreen;
				case this._constants.status.false: // Status: 'False'
					return this.colorRed;
				case this._constants.status.yes: // Status: 'True'
					return this.colorGreen;
				case this._constants.status.no: // Status: 'False'
					return this.colorRed;
				case this._constants.status.inactive: // Status: 'I'
					return this.colorGrey;
				case this._constants.status.inactive_long: // Status: 'Inactive'
				case this._constants.status.inactive_long.toUpperCase():
					return this.colorGrey;
				case this._constants.status.expired: // status: Expired
					return this.colorRed;
				case this._constants.status.outfleeted: // status: Expired
					return this.colorRed;
				default:
					return this.colorGrey;
			}
		} else {
			return this.status === this._constants.status.active ? this.colorGreen : this.colorGrey;
		}
	}

	getTooltipText() {
		if (this.tooltipText) {
			return this.tooltipText;
		}
		return this.globeStatus ? this.getStatusDescriptionByGlobeStatus() : this.getDefaultStatusText() ;
	}

	getStatusDescriptionByGlobeStatus() {
		const now = moment(moment().format(this._constants.dateFormat.YYYY_MM_DD_hyphen));
		const expiryDate = moment(moment(this.globeExpiryDate).format(this._constants.dateFormat.YYYY_MM_DD_hyphen));
		const description = this.globeStatus.description.trim().toUpperCase();

		if (expiryDate < now || description == this._constants.status.rejected.toUpperCase() || description == this._constants.status.cancelled.toUpperCase() || description == this._constants.status.inactive_long.toUpperCase()) {
			return this._labels.inactive;
		}

		if (description === this._constants.status.approved.toUpperCase()) {
			return this._labels.active;
		} else {
			return this._labels.pending;
		}
	}

	getDefaultStatusText() {
		if (this.isStatus) {
			if (this.statusTooltip) {
				return this.statusTooltip;
			} else {
				switch (this.status) {
					case this._constants.status.active: // Status: 'Active'
						return this._labels.active;
					case this._constants.status.deactive: // Status: 'DeActive'
						return this._labels.deactive;;
					case this._constants.status.pending: // Status: 'Pending'
						return this._labels.pending;
					case this._constants.status.not_applied: // Status: 'Not Applied'
						return this._labels.not_applied;
					case this._constants.status.true: // Status: 'True'
						return this._labels.active;
					case this._constants.status.yes: // Status: 'True'
						return this._labels.yes;
					case this._constants.status.no: // Status: 'False'
						return this._labels.no;
					case this._constants.status.inactive:
					case this._constants.status.inactive_long: // Status: 'Inactive'
					case this._constants.status.inactive_long.toUpperCase():
						return this._labels.inactive;
					case this._constants.status.outfleeted: // Status: 'False'
						return this._labels.outfleeted;// Status: 'Inactive'
					case this._constants.status.active_long:
					case this._constants.status.active_long.toUpperCase():
						return this._labels.active
					default:
						return this._labels.inactive;
				}
			}
		} else {
			return this.status === this._constants.status.active ?
				this._labels.active :
				this._labels.inactive;
		}
	}
}