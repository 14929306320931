import { Output, ViewChild, Input, EventEmitter, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { EButtonDirective } from 'src/app/resource/enums/e-button-directive.enum';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
	selector: 'app-button-load',
	templateUrl: './button-load.component.html',
	styleUrls: ['./button-load.component.scss']
})
export class ButtonLoadComponent implements OnInit, AfterViewInit {
	@Input() isLoading: boolean = false;
	@Input() buttonText: string = '';
	@Input() buttonDirective: any;
	@Input() disabled: boolean;
	@Input() size: string;
	@Input() extraClass: string[];
	@Input() srcIcon: string;
	@Input() isIconRight: boolean;
	@Output() action = new EventEmitter();

	@ViewChild('textButton', { static: false }) textButton: ElementRef;
	@ViewChild('button', { static: false }) button: ElementRef;

	private _icons = Icons.icon;

	public _imgSrcSpinner = Icons.getIconPath(this._icons.loading);
	public _eButtonDirective = EButtonDirective;
	public textWidth = 20; // loading icon default size
	public _labels = Labels.getLabels();

	private clickDebounce: boolean = false;

	constructor(private renderer: Renderer2) { }

	ngOnInit() {
		this.isLoading = false;
	}

	loading() {
		if (this.clickDebounce) {
			return;
		}
		
		this.clickDebounce = true; 
		
		this.action.emit();

		setTimeout(() => {
			this.clickDebounce = false; 
		}, 500);
	}

	ngAfterViewInit() {
		if (this.textButton.nativeElement.offsetWidth) { this.textWidth = this.textButton.nativeElement.offsetWidth; }

		this.renderer.addClass(this.button.nativeElement, this.size + '-button');

		if (this.extraClass && this.extraClass.length > 0) {
			this.extraClass.forEach(cssClass => { 
				this.renderer.addClass(this.button.nativeElement, cssClass);
			});
		}
	}
}
