import { ProductCharacteristic } from '../components/product-card/product-card.component';
import { CharacteristicTypeEnum } from '../enum/characteristic-type.enum';

/**
 * Determines if an automatic click action is allowed based on the characteristics of a product.
 *
 * This function evaluates each product characteristic to decide if an automatic click is permissible.
 * Characteristics of type BOOLEAN are immediately disqualified. For characteristics of types TEXT and NUMBER,
 * there must not be any customer input set to true, and exactly one option must be available. For SELECT type characteristics,
 * the presence of exactly one option suffices, regardless of customer input.
 *
 * @param {ProductCharacteristic[]} characteristics - An array of product characteristics to evaluate.
 * @returns {boolean} - Returns true if an automatic click is allowed based on the provided characteristics, false otherwise.
 */
export const isAutomaticClickAllowedBasedOnCharacteristics = (
	characteristics: ProductCharacteristic[]
): boolean => {
	// Ensure characteristics array is not empty
	if (!characteristics || !characteristics.length) return false;

	return characteristics.every(
		({ characteristicType, customerInput, options }) => {
			// Disqualify BOOLEAN type or any characteristic with more than one option or customer input set to true
			if (
				characteristicType === CharacteristicTypeEnum.BOOLEAN ||
				options.length !== 1 ||
				customerInput === true
			)
				return false;

			// For TEXT and NUMBER types, there must be exactly one option and customer input should not be true
            // For SELECT type, only one option needs to be available, regardless of customer input
			const isTextOrNumberType =
				characteristicType === CharacteristicTypeEnum.TEXT ||
				characteristicType === CharacteristicTypeEnum.NUMBER;
			const isSelectType = characteristicType === CharacteristicTypeEnum.SELECT;

			return isTextOrNumberType || isSelectType;
		}
	);
};