import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { isEyt, isProd, isUat } from 'src/environments/environment';
import { Constants } from '../../../constants';
import { EActionsType } from '../enums/e-actions.enum';
import { ECategoriesType } from '../enums/e-categories.enum';
import { EElementsType } from '../enums/e-elements.enum';
import { EModulesType } from '../enums/e-modules.enum';
import { ERolesType } from '../enums/e-roles.enum';
import { ESubModulesType } from '../enums/e-sub-modules.enum';
import { LoginService } from '../service/login.service';
import { AccessService } from '../service/access.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
	private _constants = Constants.constant;
	constructor(
		private loginService: LoginService,
		private accessService: AccessService,
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.check(state.url);
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.check(state.url);
	}

	isLoginPage(url) {
		return url.includes('/#/login');
	}

	check(path: string) {
		if (this.isLoginPage(path)) true;
		return new Observable<boolean>((observer) => {
			this.accessService
				.trySilentToken()
				.then(async (data) => {
					if (this.accessService.currentToken.isValid) {
						this.accessService.useCachedUser();
						observer.next(true);
						observer.complete();
					} else {
						const token = await this.accessService.getValidTokenInData(data);
						console.log(token, '68.verifyToken')
						this.accessService
							.authenticate('Bearer ' + token, false)
							.then(() => {
								observer.next(true);
								observer.complete();
							});
					}
					// this.accessService.authenticate(data)
				})
				.catch((err) => {
					this.accessService.fetchTokenSilently(false).then(() => {
						observer.next(true);
						observer.complete();
					});
				});
		});
	}

	userHasRole(
		category: ECategoriesType,
		role: ERolesType,
		module: EModulesType,
		submodule: ESubModulesType,
		element: EElementsType,
		action: EActionsType
	): boolean {
		const roleConcat = category
			.replace(/[^a-z0-9]/gi, '')
			.concat('-')
			.concat(module.replace(/[^a-z0-9]/gi, ''))
			.concat('-')
			.concat(submodule.replace(/[^a-z0-9]/gi, ''))
			.concat('-')
			.concat(role.replace(/[^a-z0-9]/gi, ''))
			.concat('-')
			.concat(element.replace(/[^a-z0-9]/gi, ''))
			.concat('-')
			.concat(action.replace(/[^a-z0-9]/gi, ''));

		const evaluation = this.loginService.user.roleList.some(
			(item) => (item as unknown as string) === roleConcat
		);
		return evaluation;
	}

	userHasOneOfListedRoles(
		category: ECategoriesType,
		rolesList: Array<ERolesType>,
		module: EModulesType,
		submodule: ESubModulesType,
		element: EElementsType,
		action: EActionsType
	): boolean {
		const hasRole: Array<boolean> = [];
		rolesList.forEach(role => {
			hasRole.push(this.userHasRole(category,	role,	module,	submodule, element, action));
		});
		return hasRole.some((hasRole: boolean) => hasRole === true);
	}
}
