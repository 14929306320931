// truncate.service.ts
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TruncateService {
	truncateText(text: string, limit: number): string {
		console.log('Texto original:', text);

		if (text === undefined) {
			console.log('Texto é undefined. Retornando string vazia.');
			return '';
		}

		const truncatedText = text.length > limit ? text.substring(0, limit) + '...' : text;
		console.log('Texto truncado:', truncatedText);
		return truncatedText;
	}
}
