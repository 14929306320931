import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDoubledot'
})
export class TimeDoubledotPipe implements PipeTransform {

	transform(value: string): string {
		if(!value) return value;
		const cleanedValue = value.replace(/\D/g, '');

		if (cleanedValue) {
			const paddedValue = cleanedValue.padStart(4, '0');

			const hours = paddedValue.substring(0, 2);
			const minutes = paddedValue.substring(2, 4);

			return hours + ':' + minutes;
		}

		return '';
	}


}
