import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material';
import { Subscription } from 'rxjs';
import { DropdownOptionsModel } from 'src/app/shared/model/dropdown-options.model';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';
import { LocaleSwitcher } from 'src/internationalization/locale-switcher';
import { isAutomaticClickAllowedBasedOnCharacteristics } from '../../utils/isAutomaticClickAllowedBasedOnCharacteristics';

export interface ProductCharacteristic {
	automaticallyFilled: boolean;
	id: number;
	description: string;
	measurementUnit?: any;
	characteristicType: string;
	customerInput: boolean;
	booleanDefault?: any;
	required: boolean;
	value: any
	dirty: boolean
	orderIndex: number;
	options: any[]
	shouldDisable?: any[] | undefined;
}

export interface CartCheckbox {
	value: boolean;
	disabled: boolean;
}

export interface Product {
	id: number;
	name: string;
	description?: any;
	requirement: boolean;
	attachmentList: any[];
	productCharacteristics: ProductCharacteristic[];
	cartCheckbox: CartCheckbox;
	disabled: boolean
	selected: boolean
	impacts?: any[]
	stepperPosition: number
	invalid: boolean
	onEdit: boolean
	showAlert: boolean
	repeated: boolean
	ready: boolean
	isEditing: boolean
	productInstance: boolean
	nextDisabled: boolean
	noInstance?: boolean
	getDataFromEditWhenProductComesWithNoPair?: boolean
	getDataFromEditWhenProductComesWithPair?: boolean
	previousProductFilling?: any
	shouldDisable?: any[];
	agreementCharacteristic?: Array<any[]>;
	oceanTransportIsInvalid?: boolean;
	filled: boolean;
	touches: number;
	hasOnlyOneOption: boolean;
}

@Component({
	selector: 'app-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {
	@Input() icon: string;
	@Input() title: string;
	@Input() value;
	@Input() index: number;
	@Input() checkboxValue: boolean;
	@Input() disableCheckbox: boolean = false;
	@Input() disableDetails: boolean = false;
	@Input() availableEdit: boolean = true;
	@Input() disableEdit: boolean = false;
	@Input() errorMessage: boolean = false;
	@Input() productsAreReadyToSave: boolean = false;
	@Input() stepper: number;
	@Input() isDevMode: boolean;
	@Input() editCopyOrRefresh: boolean;
	@Input() hasInvalidOceanTransport: boolean;
	@Output() toggleCheckProductHandler = new EventEmitter();
	@Output() onClick = new EventEmitter<any>();
	@Output() onClickNext = new EventEmitter<any>();
	@Output() valueChangeCharactDropdownEmit = new EventEmitter<any>();
	@Output() valueChangeInputTextEmit = new EventEmitter<any>();
	@Output() editCharacteristics = new EventEmitter<any>();
	@Output() valueChangeSearchEmit = new EventEmitter<any>();
	@Output() overlay = new EventEmitter<boolean>();

	@Input()
	set updateCount(value: any) {
		if (value > 0) {
			this.updateCustomerInputValues()
		}
	}

	@Input() product: Product;
	relatedProductName;
	positionOptions: TooltipPosition[] = [
		'after',
		'before',
		'above',
		'below',
		'left',
		'right',
	];
	position = new FormControl(this.positionOptions[2]);
	public _icons = Icons;
	_labels = Labels.getLabels();
	characteristicsListsDrop: DropdownOptionsModel[];
	strSearch;
	subscriptions: Subscription[] = [];

	public customPatterns = {'V': {pattern: new RegExp('^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$')}};
	@ViewChild("productCard", {static: false}) productCard: ElementRef;

	//next automatic and disabled next button
	productCurrentId: number;
	productPreviousId: number;
	automaticClickSelected: boolean;
	indexDescriptionEmpty:number = -1;
	isValueUndefined: boolean = false;

	constructor() { }

	updateCustomerInputValues() {
		setTimeout(() => {
			this.productCard.nativeElement.querySelectorAll('.search-input').forEach(el => {
				el.value = '';
			})
			this.productCard.nativeElement.querySelectorAll('.forced-fill-input').forEach(el => {
				const eltext = el.parentNode.querySelector('mat-label').textContent.replace("*", "")
				const currChar = this.product.productCharacteristics.find(pc => pc.description === eltext)
				if (currChar) {
					el.value = currChar.value.description
					el.addEventListener('click', e => {
						e.selectionStart = 1
						setTimeout(() => {
							if (el !== null) {
								if (el.createTextRange) {
									var range = el.createTextRange();
									range.move('character', el.value.length);
									range.select();
									return true;
								} else {
									if (el.selectionStart || el.selectionStart === 0) {
										el.focus();
										el.setSelectionRange(el.value.length, el.value.length);
										return true;
									} else {
										el.focus();
										return false;
									}
								}
							}
						}, 5);
					})
				}
			})
		}, 350);
	}

	changeToggle($event, characteristic) {
		this.toggleCheckProduct = $event.checked;
		this.valueChangeCharactDropdownEmit.emit({
			productId: this.product.id,
			strSearch: '',
			idCurrent: characteristic.id,
			characteristicsIndex: this.product.productCharacteristics.findIndex(pc => pc.id === characteristic.id),
			type: characteristic.characteristicType,
			value: {
				id: characteristic.id,
				description: $event.checked
			},
			touched: characteristic.touched
		});
	}

	getId(index) {
		return "card-" + this.product.stepperPosition
	}

	searchCharacteristicsLists(strSearch = '', characteristic) {
		this.valueChangeSearchEmit.emit({
			productId: this.product.id,
			strSearch: strSearch,
			idCurrent: characteristic.id,
			characteristicsIndex: this.product.productCharacteristics.findIndex(pc => pc.id === characteristic.id),
			value: {
				id: characteristic.id,
				description: ''
			},
			touched: characteristic.touched
		});
	}

	valueChangeCharactDropdown(event, characteristic) {
		this.valueChangeCharactDropdownEmit.emit({
			productId: this.product.id,
			strSearch: '',
			idCurrent: characteristic.id,
			characteristicsIndex: this.product.productCharacteristics.findIndex(pc => pc.id === characteristic.id),
			type: characteristic.characteristicType,
			value: {
				id: event.id === '0000' ? '0000' : characteristic.id,
				description: event.description
			},
			touched: characteristic.touched
		});
	}

	isTrueSet = (characteristic) => {
		if (!characteristic.value) {
			characteristic.value = {
				id: characteristic.id,
				description: characteristic.booleanDefault
			}
		}
		if (characteristic.value.description === true || characteristic.value.description === "TRUE" || characteristic.value.description === "true") return true
		if (characteristic.value.description === false || characteristic.value.description === "FALSE" || characteristic.value.description === "false") return false
		if (!characteristic.value.description) return characteristic.booleanDefault ? true : false
	}

	valueChangeInputText(event, characteristic) {
		this.valueChangeInputTextEmit.emit({
			productId: this.product.id,
			strSearch: '',
			idCurrent: characteristic.id,
			characteristicsIndex: this.product.productCharacteristics.findIndex(pc => pc.id === characteristic.id),
			type: "TEXT",
			value: {
				id: characteristic.id,
				description: event.target.value
			}
		});
	}

	getInvalidTooltip() {
		if (this.product.invalid) return "This product is not available for this quotation, review your request."
	}

	getTooltip(characteristic) {
		if (characteristic.value) {
			if (characteristic.value.description) {
				return characteristic.value.description.length > 14 ? characteristic.value.description.replace(/;/g, "; ") : ''
			}
		}
		return ''
	}

	getImpacts(characteristic) {
		return this.product.impacts ? this.product.impacts.find(p => p.id === characteristic.id) : false
	}

	getYesNoLabel(characteristic) {
		if (characteristic.value.description === true || characteristic.value.description === "TRUE" || characteristic.value.description === "true") return "Yes"
		if (characteristic.value.description === false || characteristic.value.description === "FALSE" || characteristic.value.description === "false") return "No"
		if (!characteristic.value.description) return characteristic.booleanDefault ? "Yes" : "No"
	}

	onClickEdit() {
		this.editCharacteristics.emit({
			product: this.product,
		});
	}
	toggleCheckProduct() {
		const currentSelectedStatus = this.product.selected ? true : false
		this.toggleCheckProductHandler.emit({
			product: this.product,
			event: !currentSelectedStatus
		})
	}

	tooltipInvalidEnable() {
		return !this.product.invalid
	}

	// Old validation before ADOINCS-69157 
	// validateCharacteristicsAllowClickAutomatic(): boolean {
	// 	return (this.product.productCharacteristics.some(pc => (pc.customerInput || pc.characteristicType === 'TEXT' || pc.characteristicType === 'NUMBER' || pc.characteristicType === 'BOOLEAN')));
	// }

	// Old validation before ADOINCS-69157 
	// validateChacteristicHasOneOption(): boolean {
	// 	if (this.product.touches === 0) {
	// 		return this.product.productCharacteristics.every((characteristic) => (characteristic.options.length === 1));
	// 	}
	// 	return false;
	// }

	// old
	// validCharacteristics(): boolean {
	// 	//go through the characteristics of product if it returns true it's because there is some empty characteristics 
	// 	//save the index value of the characteristic that is not filled in properly
	// 	this.isValueUndefined = false;
	// 	this.indexDescriptionEmpty = this.product.productCharacteristics.findIndex(characteristic => {
	// 		if (characteristic.value === undefined) {
	// 			this.isValueUndefined = true;
	// 			return false;
	// 		}
	// 		return characteristic.value.description === '';
	// 	});
	// 	//if is -1 and isValueUndefine for false is because all the characteristics are filled
	// 	return (this.indexDescriptionEmpty === -1 && !this.isValueUndefined) ? false : true; 
	// }

	// //returns false when products are not filled properly
	// validAllCharacteristics(): boolean {
	// 	//if method privateValidCharacteristics returns true because it has characteristics filled in as empty
	// 		if (this.validCharacteristics()) {
	// 			//if the value is undefined, it is because there is no characteristic filled in
	// 			if (this.isValueUndefined) {
	// 				return false;
	// 			}
	// 			//with the index value, check if the characteristic has filling options
	// 			//if you have no options, you must accept the empty value
	// 			if (this.product.productCharacteristics[this.indexDescriptionEmpty].options.length == 1) {
	// 				//compare whether the filled value and the only option- if the only option ithe system have to accept
	// 				return this.product.productCharacteristics[this.indexDescriptionEmpty].options[0].description === this.product.productCharacteristics[this.indexDescriptionEmpty].value.description ? true : false; 
	// 			} else {
	// 				return false;
	// 			}
	// 		} else {
	// 			//all characteristics are filled with valid values
	// 			return true;
	// 		}
	// }

	private isCustomerInputEmpty(): boolean {
		return this.product.productCharacteristics.some(pc => pc.value && pc.value.description === '' && pc.customerInput);
	}
	
	private isOptionDescriptionEmpty(): boolean {
		return this.product.productCharacteristics.some(pc => 
			!pc.value || 
			(pc.value.description === "" && pc.options.length && !pc.options.some(op => op.description === ""))
		);
	}

	isNextDisabled() {
		//old validation before US: 67072
		// if (this.product.productCharacteristics.some(pc => pc.value && pc.value.description === '' && pc.customerInput)) return true;
		// return this.product.productCharacteristics.some(pc => !pc.value || (pc.value.description === "" && pc.options.length && !pc.options.some(op => op.description === ""))) || this.product.nextDisabled

		if (
			this.isCustomerInputEmpty() ||
			this.isOptionDescriptionEmpty() ||
			this.product.nextDisabled
		) {
			return true;
		} else {
			if (this.canProceedWithAutoClick()) {
				this.overlay.emit(true);
				this.nextAutomatic();
			}
			return false;
		}
	}

	canProceedWithAutoClick(): boolean {
		return (
			isAutomaticClickAllowedBasedOnCharacteristics(
				this.product.productCharacteristics
			) &&
			this.product.touches === 0 &&
			!this.product.isEditing
		);
	}

	onClickButton(event) {
		this.onClick.emit(event);
	}

	onClickNextButton(event?) {
		this.onClickNext.emit(event);
		this.overlay.emit(false);
	}

	myBlur() {
		console.log('blur done!')
	}

	logStuff() {
		console.log(this)
	}


	getProductName(characteristic) {
		let wasImpactedMessage = this._labels.icon_gray;
		let locale = LocaleSwitcher.getLocale();
		if (!this.product.impacts.length || this.product.name === "Handling") return ''
		switch (locale) {
			case 'pt':
			case 'pt-br':
				wasImpactedMessage = wasImpactedMessage.replace('Produto 1', this.product.impacts.find(p => p.id === characteristic.id).name);
				break;
			case 'en':
			case 'en-us':
				wasImpactedMessage = wasImpactedMessage.replace('Product 1', this.product.impacts.find(p => p.id === characteristic.id).name);
				break;
			case 'es':
			case 'es-es':
				wasImpactedMessage = wasImpactedMessage.replace('Producto 1', this.product.impacts.find(p => p.id === characteristic.id).name);
				break;
		}
		return wasImpactedMessage;
	}

	showImpactTag(id) {
		return this.product.impacts.some(impact => impact.id === id)
	}

	showEdit() {
		return (
			this.product.requirement &&
			this.product.selected &&
			this.product.stepperPosition !== this.stepper &&
			this.product.name !== 'Handling'
		)
	}

	disableDropdown(characteristic): boolean {
		let validation = ((!this.product.requirement && !this.productsAreReadyToSave) ||
			(this.product.requirement && this.product.stepperPosition !== this.stepper) ||
			this.product.name === 'Handling' ||
			(characteristic.disabled && !characteristic.touched) ||
			this.getImpacts(characteristic) ||
			characteristic.notEditableByUser);

		if (characteristic && characteristic.options) {
			return (characteristic.options.length == 0 || validation);
		} else {
			return validation;
		}
	}

	popoverPlacement(id) {
		const characteristicIndex = this.product.productCharacteristics.findIndex(pc => pc.id === id)
		if ([2, 5, 8, 11].includes(characteristicIndex)) return 'top-right'
		return 'top-left';
	}

	getPlaceholderInside(characteristic) {
		if (!characteristic.value) return this._labels.select + '...'
		if (characteristic.value.description === '') {
			if (!characteristic.options) return this._labels.select + '...'
			if (characteristic.options.length === 1) return ' '
			return this._labels.select + '...'
		}
	}

	clickAutomatic() {
		setTimeout(() => {
			this.onClickNextButton();
		}, 2000); 
	}

	nextAutomatic(): void {
		this.productCurrentId = this.product.id;
			if (this.productPreviousId !== this.productCurrentId) {
				this.productPreviousId = this.productCurrentId;
				this.productCurrentId = this.product.id;
				this.clickAutomatic();
			}
			if (this.product.showAlert && this.product.hasOnlyOneOption) {
				this.product.showAlert = false;
				this.clickAutomatic();
			}
	}

}
