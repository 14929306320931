import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatCalendarCellCssClasses, MatDatepicker, MatDatepickerToggle } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { Icons } from 'src/icons';
import { Messages } from 'src/internationalization/messages/messages';
import { ToastService } from '../../service/toast.service';

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: Constants.constant.component.FORMATS },
	]
})
export class DatepickerComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('input', { static: false }) input;
	@ViewChild('pickerToggle', { static: false }) pickerToggle: MatDatepickerToggle<any>;
	@ViewChild('picker', { static: false }) picker: MatDatepicker<any>;

	@Input() value: string;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() width: string;
	@Input() validateCurrentDateMessage: string;
	@Input() disabled = false;
	@Input() dateToComparePeriod: string;
	@Input() isBeforeCompare: boolean;
	@Input() checkToday: boolean;
	@Input() validatePeriodInitialMessage: string;
	@Input() validatePeriodFinalMessage: string;
	@Input() err: boolean;
	@Input() dateFilter: boolean;
	@Input() floatLabel: string = 'auto';
	@Input() datepickerCustomized: boolean = false;
	@Input() deadlineApprovedInitial: any;
	@Input() deadlineApprovedFinished: any;
	@Input() deadlineSystemInitial: any;
	@Input() deadlineSystemFinished: any;


	@Output() valueChange = new EventEmitter();
	@Output() focus = new EventEmitter();
	@Output() blur = new EventEmitter();
	@Output() keyDownEnter = new EventEmitter();

	public minDate = new Date(new Date().setHours(0, 0, 0, 0));
	public date;
	public _icons = Icons;

	private _messages = Messages.getMessages();
	private _constants = Constants.constant;
	private _dateFormatSlash: string = this._constants.dateFormat.DD_MM_YYYY_slash;
	private _dateFormatHyphen: string = this._constants.dateFormat.YYYY_MM_DD_hyphen;

	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor(private toastService: ToastService) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value) {
			if (changes.value.currentValue) {
				if (this.value.indexOf('-') >= 0) {
					const array = this.value.split('-');
					if (array.length > 0) {
						const value = array[2] + '/' + array[1] + '/' + array[0];
						this.setDate(value);
					}
				}
			} else {
				setTimeout(() => {
					this.date = undefined;
					this.value = undefined;
					this.input.nativeElement.value = '';
				});
			}
		}
	}

	ngAfterViewInit() {
		if (this.datepickerCustomized) {
			this.listingCalendarToApllyColor()
		}
	}

	/**
	 * Method for 'listening' to events involving calendar changes
	 * due to the version of Angular Material (version 8) not having the support to define the class applied to each calendar cell 
	 * necessary to 'listen' to calendar interactions and apply the custom class
	 * Events: opening the calendar (openedStream), changing the month (monthSelected), changing the year (yearSelected), browsing by months (prevButton and nextButton)
	 */
	listingCalendarToApllyColor() {
		if (this.pickerToggle) {
			this.pickerToggle.datepicker.openedStream.subscribe(() => {
				this.applyDateClasses();
			});

			this.pickerToggle.datepicker.monthSelected.subscribe(() => {
				this.applyDateClasses();
			});

			this.pickerToggle.datepicker.yearSelected.subscribe(() => {
				this.applyDateClasses();
			});
		}

		this.picker.openedStream.subscribe(() => {
			setTimeout(() => {
				const prevButton = document.querySelector('.mat-calendar-previous-button');
				const nextButton = document.querySelector('.mat-calendar-next-button');

				if (prevButton || nextButton) {
					prevButton.addEventListener('click', () => {
						this.applyDateClasses();
					});

					nextButton.addEventListener('click', () => {
						this.applyDateClasses();
					});
				}
			});
		});
	}

	onFocus() {
		if (this.value && this.value.length == 10) {
			const array = this.value.split('-');
			this.value = array[2] + '/' + array[1] + '/' + array[0];
		}

		this.focus.emit();
	}

	setDate(value) {
		if (!value || value.split('-').length != 3) {
			if (value && value.length == 10) {
				this.date = moment(value, this._constants.dateFormat.DD_MM_YYYY_slash);

				const array = value.split('/');
				if (this.value != array[2] + '-' + array[1] + '-' + array[0]) {
					this.value = array[2] + '-' + array[1] + '-' + array[0];
				}
			} else {
				this.date = undefined;
				this.value = undefined;
				this.input.nativeElement.value = '';
			}

			this.valueChange.emit(this.value);
		}
	}

	change(a, b) {
		switch (a) {
			case 'input':
				b.target.value = this.validate(b.target.value);

				if (b.target.value && b.target.value.length == 10) {
					this.setDate(b.target.value);

					this.validateCurrentdate();
					this.validatePeriod();
				} else if (b.target.value.length == 0) {
					this.valueChange.emit(undefined);
				} else {
					this.value = b.target.value;
				}
				break;

			case 'dateChange':
				this.setDate(b.targetElement.value);
				break;

			case 'blur':
				this.setDate(this.value);
				this.validateCurrentdate();
				this.validatePeriod();
				this.blur.emit();
				break;

			case 'ngModelChange':
				if (b && b._i && b._i[this._constants.component.year] != undefined
					&& b._i[this._constants.component.month] != undefined
					&& b._i[this._constants.component.date] != undefined) {
					const m = b as moment.Moment;
					this.value = m.format(this._dateFormatHyphen);

					this.validateCurrentdate();

					this.validatePeriod();

					this.valueChange.emit(this.value);
					this.keyDownEnter.emit();
					this.triggerHiddenInput();
				}

				break;
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	validateCurrentdate() {
		if (this.validateCurrentDateMessage && this.date != undefined) {
			const m: moment.Moment = this.date;

			if (m.isBefore(moment().startOf('day'))) {
				const msg = this._messages.must_be_equal_or_higher_than
					.replace('{0}', this.validateCurrentDateMessage)
					.replace('{1}', moment().format(this._dateFormatSlash));

				if (this.err) {
					this.toastService.show(this._constants.messageType.error, msg);
				} else {
					this.toastService.show(this._constants.messageType.warning, msg);
				}
				this.setDate(undefined);
			}
		}
	}

	validatePeriod() {
		if (this.validatePeriodInitialMessage && this.validatePeriodFinalMessage
			&& this.dateToComparePeriod && this.date != undefined) {
			const compare = this.compareDate(this.dateToComparePeriod);

			if ((this.isBeforeCompare && compare > 0)
				|| (!this.isBeforeCompare && compare < 0)) {
				const msg = this._messages.must_be_higher_or_equal
					.replace('{0}', this.validatePeriodFinalMessage)
					.replace('{1}', this.validatePeriodInitialMessage);
				this.toastService.show(this._constants.messageType.error, msg);
				this.setDate(undefined);
			}
		}
	}

	validate(value) {
		if (value) {
			value = value.replace(/\D/g, '');

			if (value.length > 2) {
				if (value.length <= 4) {
					value = value.substring(0, 4).replace(/^(\d{2})?(\d{2})?/, '$1/$2');
				} else {
					value = value.substring(0, 8).replace(/^(\d{2})?(\d{2})?(\d{4})?/, '$1/$2/$3');
				}
			}
		}

		let day;
		let month;
		switch (value.length + '') {
			case '1':
				if (Number(value.substring(0, 1)) > 3) {
					value = '';
				}
				break;
			case '2':
				if (this.isNotValidDay(value)) {
					value = value.substring(0, 1);
				}
				break;
			case '4':
				if (this.isNotValidDay(value)) {
					value = value.substring(0, 1);
				} else if (Number(value.substring(3)) > 1) {
					value = value.substring(0, 2);
				}
				break;
			case '5':
				if (this.isNotValidDay(value)) {
					value = value.substring(0, 1);
				} else if (this.isNotValidMonth(value)) {
					value = value.substring(0, 4);
				}
				break;
			case '6':
			case '7':
			case '8':
			case '9':
				if (this.isNotValidDay(value)) {
					value = value.substring(0, 1);
				} else if (this.isNotValidMonth(value)) {
					value = value.substring(0, 4);
				}
				break;
			case '10':
				if (this.isNotValidDay(value)) {
					value = value.substring(0, 1);
				} else if (this.isNotValidMonth(value)) {
					value = value.substring(0, 4);
				} else {
					day = Number(value.substring(0, 2));
					month = Number(value.substring(3, 5));

					if (month == 2 && day > 28) {
						const year = Number(value.substring(6));
						if (!(((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0))) {
							value = value.substring(0, 9);
						}
					}
				}

				break;
		}

		return value;
	}

	isNotValidDay(value) {
		return Number(value.substring(0, 2)) > 31;
	}

	isNotValidMonth(value) {
		const day = Number(value.substring(0, 2));
		const month = Number(value.substring(3, 5));

		return (this.isThirtyDaysMonth(month) && day > 30) ||
			(month == 2 && day > 29) || month > 12;
	}

	isThirtyDaysMonth(month: number) {
		return month == 4 || month == 6 || month == 9 || month == 11;
	}

	compareDate(dateToCompare) {
		const a = moment(this.value, this._constants.dateFormat.YYYY_MM_DD_hyphen);
		const b = moment(dateToCompare, this._constants.dateFormat.YYYY_MM_DD_hyphen);

		if (a.isBefore(b)) {
			return -1;
		} else if (a.isAfter(b)) {
			return 1;
		} else {
			return 0;
		}
	}

	closed() {
		this.pickerToggle._button[this._constants.general.elementRef].nativeElement[this._constants.general.blur]();
	}

	displayError() {
		const input = this.input.nativeElement.parentElement;

		if (input.className.indexOf(' input-error') < 0) {
			input.className += ' input-error';
		}
	}

	removeError() {
		const input = this.input.nativeElement.parentElement;
		input.className = input.className.replace(' input-error', '');
	}

	/**
	 * Define the color of the day cell in the calendar according to the deadlines 
	 * Variables that inform the value of the first term interval: deadlineApprovedInitial and deadlineApprovedFinished
	 * Variables that informs the second interval of the deadline that should be in another color: deadlineSystemInitial and deadlineSystemFinished	
	 */
	applyDateClasses() {
		setTimeout(() => {
			const calendarCells = document.querySelectorAll('.mat-calendar-body-cell');
			calendarCells.forEach((cell: HTMLElement) => {
				const date = moment(cell.getAttribute('aria-label').toLowerCase(), 'D [de] MMMM [de] YYYY', 'pt-br');
				if (date.isSameOrAfter(this.deadlineApprovedInitial, 'day') && date.isSameOrBefore(this.deadlineApprovedFinished, 'day')) {
					cell.classList.add('green-date');
				} else if (date.isSameOrAfter(this.deadlineSystemInitial, 'day') && date.isSameOrBefore(this.deadlineSystemFinished, 'day')) {
					cell.classList.add('yellow-date');
				} else if (date.isAfter(this.deadlineSystemFinished, 'day') || date.isBefore(this.deadlineApprovedInitial, 'day')) {
					cell.classList.remove('green-date', 'yellow-date');
				}
			});
		});
	}
}