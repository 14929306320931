export default {
    redirectUri: 'https://intercab-pp.alianca.com.br/',
    apiUri: 'https://api-stage.alianca.com.br/intercab',
	  apiMaerskUri: 'https://api-cdt.maersk.com/intercab',
    fileReference: 'environment.preprod.ts',
    ambient: 'pp' as const,
    consumerkey: 'qKDWkxl7TOIBnxGbOaj9zMC0kIkY2Iqc',
    instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
    azfUri: 'https://api-stage.alianca.com.br/intercab/function/uploadfilestoblob',
    commercialUri: 'https://api-stage.alianca.com.br/intercab' + '/v1/commercial/',
}