import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Icons} from "../../../../icons";
import {Labels} from "../../../../internationalization/labels/labels";
import {getTooltipInLongText, getTooltipUtil} from "../../utils/tooltip/tooltip.util";

@Component({
	selector: 'app-dropdown-list-reactive',
	templateUrl: './dropdown-list-reactive.component.html',
	styleUrls: ['./dropdown-list-reactive.component.scss']
})
export class DropdownListReactiveComponent implements OnInit {

	@Input() multiple: boolean = false;
	@Input() options: any[] = [];
	@Input() idProperty = 'id';
	@Input() displayProperty = 'description';
	@Input() auxDisplayProperty: string = '';
	@Input() showSelectAllOption: boolean = true;
	@Input() isRearrangeList: boolean = true;
	@Input() placeholder: string;
	@Input() disabled = false;
	@Input() value = null;
	@Input() showOptionSelected: boolean = false;
	@Input() optionSelectedList = [];
	@Input() optionSelectedListIndex?: number = null;
	@Input() selectAllLabel = "Select All"
	@Input() showSearchInput: boolean = true;
	@Input() inactiveDropdown: boolean = false;
	@Input() search = '';
	@Input() haveSelectOption?: boolean = false;
	@Input() selectOptionLabel? = "--SELECT--";
	@Input() showSelectAllLabel?: boolean = false;
	@Input() isTable?: boolean = false;
	@Input() arrayHeader: any[];
	@Input() positionMask: number = 0;

	@Output() searchTextChange = new EventEmitter();
	@Output() valueChange = new EventEmitter();
	@Output() deselectOption = new EventEmitter();

	public _icons = Icons;
	public _labels = Labels.getLabels();
	private singleSelectValue = [];
	private originalOptions = [];

	open: boolean = false;
	searchList = [];
	changeValue: boolean = false;
	_value = '';
	selectAll = {};
	select = {};

	constructor(private el: ElementRef) {
	}

	ngOnInit(): void {
		this.prepare();
	}

	ngOnChanges(change: SimpleChanges) {
		if (change.options) {
			this.prepare();
			if (change.value && this.optionSelectedList.length > 0) {
				this.optionSelectedListIndex || this.optionSelectedListIndex == 0 ? this.onChangeValuesToCheckedInList(this.searchList, this.optionSelectedList[this.optionSelectedListIndex], true)
					: this.onChangeValuesToCheckedInList(this.searchList, this.optionSelectedList, true)
			}
			this.setValues();
			if (this.inactiveDropdown || (this._value == this.placeholder)) {
				this.changeValue = false;
			}
		}
	}

	prepare() {
		this.setValueList();

		if (this.multiple) {
			this.populateSelectAllOption();
			this.initMultiSelectOptions(this.options);
		}

		if (this.haveSelectOption && !this.multiple) {
			this.populateSelectOption();
		}

		if (this.optionSelectedList) {
			this.onChangeAllValuesToChecked(this.optionSelectedList, true);
		}
	}

	setValueList() {
		this.searchList = [...this.options]
	}

	populateSelectAllOption() {
		if (this.showSelectAllOption) {
			this.selectAll = {
				[this.idProperty]: 0,
				[this.displayProperty]: this.selectAllLabel,
				checked: false,
				index: 0
			};
		}
	}

	populateSelectOption() {
		this.select = {
			[this.idProperty]: -1,
			[this.displayProperty]: this.selectOptionLabel,
		}
		this.insertSelectOption(this.searchList, this.select);
	}

	setValues() {
		if (this.value && (this.value[this.displayProperty] || (this.value[0] && this.value[0][this.displayProperty]))) {
			this.changeValue = true;
			if (this.multiple && !this.isTable) {
				if (Array.isArray(this.value)) {
					this.value.length > 1 ?
						this._value = this.transformValueInDropdown(this.value[0][this.displayProperty]) + " (+" + (this.value.length - 1) + ")"
						: this._value = this.transformValueInDropdown(this.value[0][this.displayProperty]);
					this.onChangeValuesToCheckedInList(this.searchList, this.value, true);
				}
			} else if (this.isTable) {
				if(this.value.length > 1) {
					this._value = this.transformValueInDropdown(this.value[0][this.displayProperty][1] + " (+" + (this.value.length - 1) + ")")
				} else {
					if(this.value[0][this.idProperty] == 0 || this.value[0][this.idProperty] == -1) {
						this._value = this.transformValueInDropdown(this.value[0][this.displayProperty]);
					} else {
						this._value = this.transformValueInDropdown(this.value[0][this.displayProperty][1]);
					}
				}
				this.onChangeValuesToCheckedInList(this.searchList, this.value, true)
			} else {
				this._value = this.transformValueInDropdown(this.value[this.displayProperty]);
			}
		} else {
			this.changeValue = false;
			this._value = this.placeholder;
		}
	}

	openDropdown() {
		this.open = !this.open;
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent) {
		if (!this.el.nativeElement.contains(event.target) && !event['consumed']) {
			if (this.multiple) {
				this.searchList = this.originalOptions;
			}
			this.open = false;
		}
	}

	consumeClick(event: Event) {
		event['consumed'] = true;
	}

	onChangeAllValuesToChecked(options, isChecked) {
		options.forEach(option => {
			option.checked = isChecked;
		});
	}

	onChangeValueToChecked(option, isChecked) {
		option.checked = isChecked;
	}

	onChangeValuesToCheckedInList(list, listOptions, isChecked) {
		if (list != undefined && listOptions != undefined) {
			list.forEach(li => {
				listOptions.forEach(lo => {
					let option = 0;
					if (lo.length >= 1) {
						option = lo[0][this.idProperty]
					} else {
						option = lo[this.idProperty]
					}
					if (li[this.idProperty] == option) {
						li.checked = isChecked;
					}
					this.auxValueChange(li, isChecked);
				})
			});
		}
	}

	onChangeValueToCheckedInList(list, option, isChecked) {
		list.forEach(li => {
			if (li[this.idProperty] == option[this.idProperty]) {
				li.checked = isChecked;
			}
		})
	}

	onChangeAllValuesToIndex(options) {
		options.forEach((option, index) => {
			option.index = index + 1;
		});
	}

	initMultiSelectOptions(options) {
		this.onChangeAllValuesToChecked(options, false);
		this.onChangeAllValuesToIndex(options);
		if (this.showSelectAllOption) {
			this.insertSelectOption(this.searchList, this.selectAll);
		}
		this.originalOptions = this.searchList;
	}

	insertSelectOption(list, obj) {
		list.unshift(obj);
	}

	onSingleSelectValueChange(event) {
		if (event.id != -1) {
			this.singleSelectValue = event;
			this.changeValue = true;
			this._value = this.transformValueInDropdown(event[this.displayProperty]);
		} else {
			this.changeValue = false;
			this._value = this.placeholder;
		}

		this.open = false;
		this.valueChange.emit(event)
	}


	onMultiSelectValueChange(event) {
		this.changeValue = true;
		const isChecked = !event.checked;

		this.onChangeValueToChecked(event, isChecked);

		const selectAllChecked = this.searchList.some(value => value[this.idProperty] == 0 && value.checked);

		if (selectAllChecked && event[this.idProperty] === 0) {
			this.onChangeAllValuesToChecked(this.searchList, false);
			this.onChangeValueToCheckedInList(this.searchList, this.selectAll, true);
		} else {
			this.onChangeValueToCheckedInList(this.searchList, this.selectAll, false);

			this.auxValueChange(event, isChecked);

			if (this.isRearrangeList) {
				this.reorderList(isChecked, this.searchList.indexOf(event), event);
			}
		}

		if (this.optionSelectedList.length > 0) {
			this.optionChange(this.optionSelectedList)
		} else {
			this.optionChange(this.searchList);
		}

		this.valueChange.emit(this.searchList);

		this.originalOptions = this.searchList.slice();
	}

	auxValueChange(event, isChecked) {
		if (event[this.auxDisplayProperty] && isChecked) {
			const auxEvent = this.searchList.filter(ev => ev[this.idProperty] == event[this.auxDisplayProperty]);
			if (auxEvent[0]) {
				this.onChangeValueToChecked(auxEvent[0], event.checked);
			}
		} else {
			const auxChecked = this.searchList.filter(ev => ev[this.auxDisplayProperty] == event[this.idProperty] && ev.checked);
			if (auxChecked[0]) {
				this.onChangeValueToChecked(auxChecked[0], isChecked);
			}
		}
	}

	reorderList(isChecked, index, event) {
		if (isChecked) {
			this.searchList.splice(index, 1);
			let lastIndexWithChecked = this.findLastIndex(this.searchList);
			lastIndexWithChecked === -1 ? this.searchList.splice(1, 0, event) : this.searchList.splice(lastIndexWithChecked + 1, 0, event);
		} else {
			this.matchList();
		}
	}

	matchList() {
		let checkedValues = this.searchList.filter(value => value.checked);
		let uncheckedValues = this.searchList.filter(value => !value.checked && value.index > 0);
		this.searchList = [...checkedValues, ...uncheckedValues.sort((a, b) => a.index - b.index)];
		if (this.showSelectAllOption) {
			this.insertSelectOption(this.searchList, this.selectAll);
		}
	}

	findLastIndex(list) {
		let lastIndexWithChecked = -1;
		for (let i = list.length - 1; i >= 1; i--) {
			if (list[i].checked) {
				lastIndexWithChecked = i;
				break;
			}
		}
		return lastIndexWithChecked;
	}

	optionChange(list) {
		let searchListFiltered = [];
		setTimeout(() => {
			this.optionSelectedListIndex || this.optionSelectedListIndex == 0 ?
				searchListFiltered = list[this.optionSelectedListIndex].filter(value => value.checked)
				: searchListFiltered = list.filter(value => value.checked);
			if (searchListFiltered.length > 0 && (!this.isTable || searchListFiltered[0].id == 0)) {
				this._value = searchListFiltered.length > 1 ? this.transformValueInDropdown(searchListFiltered[0][this.displayProperty])
					+ " (+" + (searchListFiltered.length - 1) + ")" : this.transformValueInDropdown(searchListFiltered[0][this.displayProperty]);
			} else if (searchListFiltered.length > 0 && this.isTable) {
				this._value = searchListFiltered.length > 1 ? this.transformValueInDropdown(searchListFiltered[0][this.displayProperty][1])
					+ " (+" + (searchListFiltered.length - 1) + ")" : this.transformValueInDropdown(searchListFiltered[0][this.displayProperty][1]);
			} else {
				this.changeValue = false;
				this._value = this.placeholder;
			}
		}, 500);
	}

	transformValueInDropdown(value) {
		return value.length > 20 ? value.substring(0, 20) + "..." : value;
	}

	onSearchInputChange(event) {
		this.search = event.target.value;
		setTimeout(() => {
			this.searchTextChange.emit(this.search);
		}, 1000);
	}

	onDeselectOption(event) {
		this.onChangeValueToCheckedInList(this.searchList, event, false);
		this.auxValueChange(event, false);
		const valuesNotChecked = this.searchList.filter(value => !value.checked);
		valuesNotChecked.forEach(value => {
			const index = this.optionSelectedList.indexOf(item => item[this.displayProperty] === value[this.displayProperty]);
			if (index != -1)
				this.optionSelectedList.splice(index, 1);
		})
		this.optionSelectedList.length > 0 ? this.optionChange(this.optionSelectedList) : this.optionChange(this.searchList);
		this.deselectOption.emit(event);
	}

	getTooltip(): string {
		return getTooltipUtil(this.disabled, this.value, this.displayProperty);
	}

	getTooltipInLongText(): string {
		this.setValues();
		return getTooltipInLongText(this.disabled, this.value, this.displayProperty);
	}

	protected readonly Array = Array;
}
