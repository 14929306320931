import { AfterContentInit, AfterViewInit, Component, ContentChild, ContentChildren, ElementRef, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/resource/service/login.service';
import { NotificationDropdownComponent } from '../../notification/notification-dropdown/notification-dropdown.component';
import { Labels } from 'src/internationalization/labels/labels';
import { SettingsMenuComponent } from '../settings-menu/settings-menu.component';
import { HttpClientService } from '../../service/http-client.service';
import { EActionsType } from 'src/app/resource/enums/e-actions.enum';
import { ECategoriesType } from 'src/app/resource/enums/e-categories.enum';
import { EElementsType } from 'src/app/resource/enums/e-elements.enum';
import { EModulesType } from 'src/app/resource/enums/e-modules.enum';
import { ERolesType } from 'src/app/resource/enums/e-roles.enum';
import { ESubModulesType } from 'src/app/resource/enums/e-sub-modules.enum';
import { Messages } from 'src/internationalization/messages/messages';
import { Icons } from 'src/icons';
import { Constants } from 'src/constants';
import { CoreUtil } from '../../core-util';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CheckTools } from '../../checkTools';
import { MasterService } from '../../service/master.service';
import { AbstractForm } from '../../abstract/abstract-form';
import { LanguageSelectorComponent } from '../../component/language-selector/language-selector.component';
import { MainMenuStructure } from '../../dto/main-menu-structure';
import { MatDialog } from '@angular/material';
import { CheckToolsFormComponent } from '../../component/check-tools/check-tools-form.component';
import { RoutesWithRoleRules, routes } from 'src/app/app-routing.module';
import { RoleValidation } from 'src/app/resource/guard/roleUrlCheck';

@Component({
	selector: 'app-master',
	templateUrl: './master.component.html',
	styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(NotificationDropdownComponent, { static: true }) notificationMenu: NotificationDropdownComponent;
	@ViewChild(SettingsMenuComponent, { static: true }) configurationMenu: SettingsMenuComponent;
	@ViewChild(LanguageSelectorComponent, { static: false }) languageSelector: LanguageSelectorComponent;
	// @ContentChild('content', { static: false }) content;

	public _labels;
	public _messages;
	public logoDir = './../../../../assets/logo/';
	public _icons = Icons;
	public veri = false;
	public fullMenu = true;
	public isConfigurationMenuOpen = false;
	public menuDisplayStructureArray: any;
	public selectedMenuItem = 'dashboard';
	public selectedMenuSubItem = null;
	public menuItemArray: MainMenuStructure[] & Partial<RoutesWithRoleRules>[]= [];
	public configurationMenuArray: any[];

	public companiesArray = [
		{ key: 'alianca', img: this.logoDir + 'alianca_logo_rgb.svg', text: 'Alianca' },
		{ key: 'hambsud', img: this.logoDir + 'hamburg_sud_logo_rgb.svg', text: 'Hamburg Sud' },
		{ key: 'maersk', img: this.logoDir + 'maersk.svg', text: 'Maersk' },
	];
	public currencyArray = [
		{ key: 'real', text: 'R$' },
		{ key: 'dollar', text: 'US$' },
	];
	public wikiMenu = [
		{ key: 'wiki', data: [] }
	];
	public notificationCounter: number;
	public currentCompany: any; // create Company Interface
	public currentCurrency: any;
	public notificationList: any[]; // Notification
	public _version = Constants.constant.version;

	private _subscriptionList: Subscription[];
	private services = [
		{ name: 'Customer', url: environment.customerHost + Constants.constant.general.version, resp: '' },
		{ name: 'Authorizer', url: environment.authorizerHost + Constants.constant.general.version, resp: '' },
		{ name: 'Additional Fee', url: environment.additionalFeesHost + Constants.constant.general.version, resp: '' },
		{ name: 'Costing', url: environment.costingHost + Constants.constant.general.version, resp: '' },
		{ name: 'Historic', url: environment.historicHost + Constants.constant.general.version, resp: '' },
		{ name: 'General Register', url: environment.generalRegisterHost + Constants.constant.general.version, resp: '' },
		{ name: 'Vessel', url: environment.vesselHost + Constants.constant.general.version, resp: '' },
		{ name: 'VesselSchedule',url: environment.scheduleHost + Constants.constant.general.version, resp: ''  },
		{ name: 'Schedule', url: environment.scheduleHost + Constants.constant.general.version, resp: '' },
		{ name: 'Product', url: environment.productHost + Constants.constant.general.version, resp: '' },
		{ name: 'Costing', url: environment.costingModuleHost + Constants.constant.general.version, resp: '' },
		{ name: 'Comercial', url: environment.commercial + Constants.constant.general.version, resp: '' },
	];
	private interval: NodeJS.Timer;

	constructor(private router: Router,
			private activatedRoute: ActivatedRoute,
			private dialog: MatDialog,
			public loginService: LoginService,
			private httpClientService: HttpClientService,
			private masterService: MasterService,
			private roleValidation: RoleValidation
			) {
	}

	ngOnInit() {
		this.updateSelfLanguage();
		this.getUser();
		this.getCurrentCompany();
		this.getCurrentCurrency();
		this.getNotificationList();
		this.setConfigurationMenu();

		const subscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.selectedMenuItem = val.url.split('/')[1];
				this.selectedMenuSubItem = this.selectedMenuItem + val.url.split('/')[2];
			}
		});

		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);

		this.getNotificationList();
	}

	// GoLive: add |isGoLive()|
	isGoLive() {
		return environment.ambient === Constants.constant.ambient.prod;
	}

	setConfigurationMenu() {
		this.configurationMenuArray = [{
			key: 'profile_management', icon: this._icons.icon.businessman, label: 'profile_management', address: 'profile_management',
			guardPathList: [
				{
					category: ECategoriesType.intercab,
					role: ERolesType.servicedesk_userprofile,
					module: EModulesType.management,
					subModule: ESubModulesType.profile,
					element: EElementsType.main_data,
					action: EActionsType.edit
				}, {
					category: ECategoriesType.intercab,
					role: ERolesType.servicedesk_userprofile,
					module: EModulesType.management,
					subModule: ESubModulesType.user_profile,
					element: EElementsType.main_data,
					action: EActionsType.edit
				}, {
					category: ECategoriesType.intercab,
					role: ERolesType.servicedesk_profile,
					module: EModulesType.management,
					subModule: ESubModulesType.profile,
					element: EElementsType.main_data,
					action: EActionsType.edit
				}, {
					category: ECategoriesType.intercab,
					role: ERolesType.servicedesk_profile,
					module: EModulesType.management,
					subModule: ESubModulesType.user_profile,
					element: EElementsType.main_data,
					action: EActionsType.edit
				}
			]
		}];
	}

	updateSelfLanguage() {
		this._labels = Labels.getLabels();
		this._messages = Messages.getMessages();

		// TODO: Waiting for better solution of labels swapping
		// if (this.content && this.content.updateLanguage) {
		// 	this.content.updateLanguage();
		// }
		this.menuDisplayStructureArray = this.masterService.getMenuDisplayStructure(this._labels);
	}

	private getRoles (el) {
		const routesFromAppRoutingModule = routes;
		const pair = routesFromAppRoutingModule.find(route => route.path.replace('_', '').replace('-', '') === el.key.replace('_', '').replace('-', ''))
		if (!pair) return el;
		return ({...el, ...pair, isDisabled: this.isDisabled(pair)})
	}

	private isDisabled (el) {
		if (!el.rolePool) return false
		return this.roleValidation.hasAccessToRoute(el) ? false : true
	}

	ngAfterViewInit() {
		this.setMenu();
		this.interval = setInterval(() => {
			if (this.languageSelector && this.languageSelector.user) {
				this.updateSelfLanguage();
				clearInterval(this.interval);
			}
		}, 300);
	}

	private setMenu() {
		switch (environment.ambient) {
			case Constants.constant.ambient.local:
				// Aways full menu
				this.menuItemArray = [
					{ key: 'dashboard' },
					{ key: 'favorites' },
					{ key: 'customer', data: [{ key: 'customer_list' }, { key: 'customer_create' }, { key: 'customer_corporate_list' }, { key: 'customer_sub_group_list' }, { key: 'customer_credit_condition' }] },
					{ key: 'general_register', data: [ { key: 'general_register_disclaimer_list' }, { key: 'general_register_exchange_rate_list'}, { key: 'general_register_port_list' }, { key: 'general_register_cities_and_states_list' }, { key:  'general_register_sales_rep_list_cdt' }, { key:  'general_register_un_number_list_cdt' }] },
					{
						key: 'product',
						data: [
							{ key: 'product_routes', data: [{ key: 'product_routes_preferred_routes_list' }] },
							{ key: 'product_vessel_capacity', data: [{ key: 'product_vessel_capacity_proforma_list' }, { key: 'product_vessel_capacity_schedule_list' }, { key: 'product_vessel_capacity_vessel_list' }] }
						]
					},
					{
						key: 'product_management',
						data: [
							{ key: 'product_management_characteristic'},
							{ key: 'product_management_products'},
						]
					},
					{ key: 'costing'},
					{ key: 'pricing'},
					{ key: 'agreement' },
					{ key: 'booking', data: [{ key: 'booking_management' } , {key: 'cargo_rollover'}]},
				].map(el => this.getRoles(el));
				break;
			case Constants.constant.ambient.cdt:
				// Aways full menu
				this.menuItemArray = [
					{ key: 'dashboard' },
					{ key: 'favorites' },
					{ key: 'customer', data: [{ key: 'customer_list' }, { key: 'customer_create' }, { key: 'customer_corporate_list' }, { key: 'customer_sub_group_list' }, { key: 'customer_credit_condition_cdt' }] },
					{ key: 'general_register', data: [ { key: 'general_register_disclaimer_list' },{ key: 'general_register_exchange_rate_list'}, { key: 'general_register_port_list' }, { key: 'general_register_cities_and_states_list' },  { key: 'general_register_port_terminals' }, { key:  'general_register_sales_rep_list_cdt' }, { key:  'general_register_un_number_list_cdt' }] },
					{ key: 'product',
						data: [
							{ key: 'product_routes', data: [{ key: 'product_routes_preferred_routes_list'}, { key: 'ad_hoc_routes' }] },
							{ key: 'product_vessel_capacity', data: [{ key: 'product_vessel_capacity_proforma_list' }, { key: 'product_vessel_capacity_schedule_list' }, { key: 'product_vessel_capacity_vessel_list' }] }
						]
					},
					{
						key: 'product_management',
						data: [
							{ key: 'product_management_characteristic'},
							{ key: 'product_management_products'},
						]
					},
					{ key: 'costing'},
					{ key: 'pricing'},
					{ key: 'commercial', data: [
							{ key: 'agreement_management' },
							{ key: 'agreement_bulk_cdt' }
						]
					},
					{ key: 'booking', data: [{ key: 'booking_management_cdt' }, {key: 'cargo_rollover_cdt'}] },
				].map(el => this.getRoles(el));
				break;
			case Constants.constant.ambient.pp:
				// Just the menu items for Pre Prod
				this.menuItemArray = [
					{ key: 'dashboard' },
					{ key: 'favorites' },
					{ key: 'customer', data: [{ key: 'customer_list' }, { key: 'customer_corporate_list' }, { key: 'customer_sub_group_list' }, { key: 'customer_credit_condition_qa' }] },
					{ key: 'general_register', data: [ { key: 'general_register_disclaimer_list' },  { key: 'general_register_exchange_rate_list'}, { key: 'general_register_port_list' }, { key: 'general_register_cities_and_states_list' }, { key: 'general_register_port_terminals' }, { key:  'general_register_sales_rep_list_qa' }, { key:  'general_register_un_number_list_qa' }] },
					{
						key: 'product', data: [
							{ key: 'product_routes', data: [{ key: 'product_routes_preferred_routes_list'}, { key: 'ad_hoc_routes' }] },
							{ key: 'product_vessel_capacity', data: [{ key: 'product_vessel_capacity_proforma_list' }, { key: 'product_vessel_capacity_schedule_list' }, { key: 'product_vessel_capacity_vessel_list' }] }
						]
					},
					{
						key: 'product_management',
						data: [
							{ key: 'product_management_characteristic'},
							{ key: 'product_management_products'}

						]
					},
					{ key: 'costing'},
					{ key: 'pricing'},
					{ key: 'commercial', data: [
							{ key: 'agreement_management' },
							{ key: 'agreement_bulk_qa' }
						]
					},
					{ key: 'booking', data: [{ key: 'booking_management_qa' } , {key: 'cargo_rollover_qa'}] },
				].map(el => this.getRoles(el));
				break;
			case Constants.constant.ambient.prod:
				// Just the menu items for Prod
				this.menuItemArray = [
					{ key: 'dashboard' },
					{ key: 'favorites' },
					{ key: 'customer', data: [{ key: 'customer_list' }, { key: 'customer_corporate_list' }, { key: 'customer_sub_group_list' }, { key: 'customer_credit_condition' }] },
					{ key: 'general_register', data: [ { key: 'general_register_disclaimer_list' }, { key: 'general_register_exchange_rate_list'}, { key: 'general_register_port_list' }, { key: 'general_register_cities_and_states_list' }, { key:  'general_register_sales_rep_list' }, { key: 'general_register_port_terminals' },] },
					{
						key: 'product', data: [
							{ key: 'product_routes', data: [{ key: 'product_routes_preferred_routes_list'}, { key: 'ad_hoc_routes' }] },
							{ key: 'product_vessel_capacity', data: [{ key: 'product_vessel_capacity_proforma_list' }, { key: 'product_vessel_capacity_schedule_list', disabled: true  }, { key: 'product_vessel_capacity_vessel_list' }] }
						]
					},
					{
						key: 'product_management',
						data: [
							{ key: 'product_management_characteristic'},
							{ key: 'product_management_products'}
						]
					},
					{ key: 'costing'},
					{ key: 'pricing'},
					{
						key: 'commercial', data: [
							{ key: 'agreement_management' },
							{ key: 'agreement_bulk' }
						]
					},
				].map(el => this.getRoles(el));
				break;
			case Constants.constant.ambient.uat:
				// Just the menu items for Prod
				this.menuItemArray = [
					{ key: 'dashboard' },
					{ key: 'favorites' },
					{ key: 'customer', data: [{ key: 'customer_list' }, { key: 'customer_corporate_list' }, { key: 'customer_sub_group_list' },  { key: 'customer_credit_condition_uat' }] },
					{ key: 'general_register', data: [ { key: 'general_register_disclaimer_list' },  { key: 'general_register_exchange_rate_list'},   { key: 'general_register_port_list' }, { key: 'general_register_cities_and_states_list' }, { key:  'general_register_sales_rep_list_uat' }, { key: 'general_register_port_terminals' },] },
					{
						key: 'product', data: [
							{ key: 'product_routes', data: [{ key: 'product_routes_preferred_routes_list'}, { key: 'ad_hoc_routes' }] },
							{ key: 'product_vessel_capacity', data: [{ key: 'product_vessel_capacity_proforma_list' }, { key: 'product_vessel_capacity_schedule_list',disabled: true }, { key: 'product_vessel_capacity_vessel_list' }] }
						]
					},
					{
						key: 'product_management',
						data: [
							{ key: 'product_management_characteristic'},
							{ key: 'product_management_products'}
						]
					},
					{ key: 'costing'},
					{ key: 'pricing'},
					{
						key: 'commercial', data: [
							{ key: 'agreement_management' },
							{ key: 'agreement_bulk_uat' }
						]
					},
				].map(el => this.getRoles(el));
				break;
			case Constants.constant.ambient.eyt:
				// Just the menu items for Prod
				this.menuItemArray = [
					{ key: 'dashboard' },
					{ key: 'favorites' },
					{ key: 'customer', data: [{ key: 'customer_list' }, { key: 'customer_corporate_list' }, { key: 'customer_sub_group_list' },  { key: 'customer_credit_condition_eyt' }] },
					{ key: 'general_register', data: [ { key: 'general_register_disclaimer_list' },  { key: 'general_register_exchange_rate_list'},   { key: 'general_register_port_list' }, { key: 'general_register_cities_and_states_list' }, { key:  'general_register_sales_rep_list_eyt' }, { key: 'general_register_port_terminals' },] },
					{
						key: 'product', data: [
							{ key: 'product_routes', data: [{ key: 'product_routes_preferred_routes_list'}, { key: 'ad_hoc_routes' }] },
							{ key: 'product_vessel_capacity', data: [{ key: 'product_vessel_capacity_proforma_list' }, { key: 'product_vessel_capacity_schedule_list',disabled: true }, { key: 'product_vessel_capacity_vessel_list' }] }
						]
					},
					{
						key: 'product_management',
						data: [
							{ key: 'product_management_characteristic'},
							{ key: 'product_management_products'}
						]
					},
					{ key: 'costing'},
					{ key: 'pricing'},
					{
						key: 'commercial', data: [
							{ key: 'agreement_management' },
							{ key: 'agreement_bulk_eyt' }
						]
					},
				].map(el => this.getRoles(el));
				break;
		}
	}

	getMenuItemRoute(key: string): string {
		const obj = this.menuDisplayStructureArray.find(obj => obj.key === key);

		if (obj) {
			return obj.route;
		} else {
			return '';
		}
	}

	getMenuItemIcon(key: string): string {
		const obj = this.menuDisplayStructureArray.find(obj => obj.key === key);

		if (obj) {
			return obj.icon;
		} else {
			return '';
		}
	}

	getMenuItemLabel(key: string): string {
		const obj = this.menuDisplayStructureArray.find(obj => obj.key === key);

		if (obj) {
			return obj.label;
		} else {
			return '';
		}
	}

	getUser() {
		// user info's get by LoginService dependency injection
	}

	getCurrentCurrency() {
		// Use this method to retrieve user's currency preference, or system's preseted currency
		this.currentCurrency = this.currencyArray[0];
	}

	getCurrentCompany() {
		// Use this method to retrieve user's company
		this.currentCompany = this.companiesArray[0];
	}

	getNotificationList() {
		// use this method to get / synchronize user's notifications
		// attention: this methodn (and the template) will need to be adaptaded
		// to attend notification's final structure
		const newNotificationList = [
			{
				category_icon: this._icons.getIconPath(this._icons.icon.customer),
				category_name: 'Customer',
				company_name: 'Company 1',
				text: 'is avaliable on InterCab. Please associate this Customer to a Corporate Group!',
				date: new Date()
			}, {
				category_icon: this._icons.getIconPath(this._icons.icon.customer),
				category_name: 'Customer',
				company_name: 'Company 2',
				text: 'is avaliable on InterCab. Please associate this Customer to a Corporate Group!',
				date: new Date()
			}, {
				category_icon: this._icons.getIconPath(this._icons.icon.customer),
				category_name: 'Customer',
				company_name: 'Company 3',
				text: 'is avaliable on InterCab. Please associate this Customer to a Corporate Group!',
				date: new Date()
			},
		];
		this.notificationList = newNotificationList;
	}

	menuShow() {
		const menu = document.getElementById('menu');
		const iconMenuAling = document.getElementById('icon-menu-aling');
		const iconItemAling = document.getElementsByClassName('icon-item-aling');

		if (this.veri === true) {
			menu.style.width = '15%';
			menu.style.transition = 'all 1s, linear;';

			iconMenuAling.classList.add('col-3');
			iconMenuAling.classList.remove('col-12');

			// tslint:disable-next-line: prefer-for-of
			for (let i = 0; i < iconItemAling.length; i++) {
				iconItemAling[i].classList.add('col-3');
				iconItemAling[i].classList.remove('col-10');
			}

			this.veri = false;
		} else {
			menu.style.width = '60px';
			menu.style.transition = 'all 1s, linear;';

			iconMenuAling.classList.add('col-12');
			iconMenuAling.classList.remove('col-3');

			// tslint:disable-next-line: prefer-for-of
			for (let i = 0; i < iconItemAling.length; i++) {
				iconItemAling[i].classList.add('col-10');
				iconItemAling[i].classList.remove('col-3');
			}

			this.veri = true;
		}
	}

	/*
	CHANGE THEME
	changeTheme(){
		document.documentElement.style.setProperty('--testColor','green');
	}*/

	searchChange(event: any) { }

	showNotifications() { }

	showConfig() { }

	onCompanyChange({ value }) { }

	onCurrencyChange({ value }) { }

	toExit() {
		this.loginService.logout();
	}

	isNew(news) {
		return news.date > new Date().getDate() - 1;
	}

	showSidebar () {
		return this.router.url !== '/login';
	}

	showVersions() {
		CheckTools.showVersions(this.services, this.httpClientService, this.loginService);
	}

	getTypeOf(el: any): string {
		return typeof el;
	}

	ngOnDestroy() {
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}
}
