import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyCharactersAndNumbers]'
})
export class OnlyCharactersAndNumbersDirective {

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const pattern = /[a-zA-Z0-9]/; // Expressão regular para caracteres alfanuméricos
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {  // se caracteres especiais foram pressionados.
      event.preventDefault();  // inibe a entrada.
    }
  }
}
