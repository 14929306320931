import {getLocation, eytUriPattern, prodUriPattern, uatUriPattern} from './location';
import {eytVariables} from './utils/eyt';
import {Constants} from 'src/constants';


export const isEyt = window.location.host.includes(eytUriPattern);
export const isUat = window.location.host.includes(uatUriPattern);
export const isProd = window.location.host.includes(prodUriPattern);

export type EnvironmentConstants = {
	production: boolean;
	ambient: keyof typeof Constants.constant.ambient;
	language: string;
	clientId: string;
	tenant: string;
	consumerkey: string;
};

export const environment = (function () {
	if (isEyt) return eytVariables;

	const environmentConstants = {
		production: true,
		ambient: isEyt ? Constants.constant.ambient.eyt : getLocation.ambient,
		language: 'en',
		clientId: 'cdb5dd96-499a-4ab6-a709-7f47e99224c5',
		tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
		consumerkey: getLocation.consumerkey
	};
	return {
		...environmentConstants,
		instrumentationKey: getLocation.instrumentationKey,
		redirectUri: getLocation.redirectUri,
		authorizerHost: getLocation.apiUri + '/authorizer/v1/authorizer/',
		customerHost: getLocation.apiUri + '/customer/v1/customer/',
		additionalFeesHost:
			getLocation.apiUri + '/additionalfee/v1/additional-fee/',
		commercial: getLocation.commercialUri,
		costingHost: getLocation.apiUri + '/cost/v1/costing/',
		costingModuleHost: getLocation.apiUri + '/costing/v1/costing/',
		historicHost: getLocation.apiUri + '/historic/v1/historic/',
		generalRegisterHost:
			getLocation.apiUri + '/generalregister/v1/general-register/',
		vesselHost: getLocation.apiUri + '/vessel/v1/vessel/',
		scheduleHost: getLocation.apiUri + '/schedule/v1/schedule/',
		localityMapperHost:
			getLocation.apiUri + '/localitymapper/v1/locality-mapper/',
		productHost: getLocation['apiMaerskUri'] + '/product/v1/',
		pricing: getLocation.apiUri + '/pricing/v1/pricing/',
		azfUploadFilesToBlob:
			getLocation.apiUri + '/function/uploadfilestoblob/api/',
		vesselHostV2: getLocation['apiUri'] + '/vessel/v2/vessel/',
		proformaHost: getLocation['apiMaerskUri'] + '/proforma/v1/',
		generalRegisterV2: getLocation['apiUri'] + '/generalregister/v2/general-register/'

	};
})();
