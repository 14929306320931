import { HttpClientService } from '../service/http-client.service';
import { ObjectMultiFilter } from '../dto/object-multi-filter.dto';
import { Pagination } from '../dto/pagination';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export abstract class AbstractService<T> {
	public observable: Observable<T>;
	public historyObservable: Observable<T>;

	protected _trigger = new BehaviorSubject<T>(undefined);
	protected _historyTrigger = new BehaviorSubject<T>(undefined);

	protected _params: string[];
	protected _microService: string = '';
	protected _endPoint: string = '';

	constructor(protected httpClientService: HttpClientService) {
		this.observable = this._trigger.asObservable();
		this.historyObservable = this._historyTrigger.asObservable();
	}

	abstract isValid(object: T): boolean;

	abstract getRequestData(object: T): any;

	abstract updateState(object: T): void;

	abstract updateHistoryState(object: T): void;

	get(queryParams: string) {
		return this.httpClientService.get(this._microService, this._endPoint, queryParams);
	}

	getById(id: number) {
		return this.httpClientService.getById(this._microService, this._endPoint, id);
	}

	getByIdWithParams(id: number, queryParams: string) {
		return this.httpClientService.getByIdWithParams(this._microService, this._endPoint, id, queryParams);
	}

	getList(strSearch: string, queryParams?: string, sortByAttribute?: string) {
		return this.httpClientService.getList(this._microService, this._endPoint, strSearch, undefined, this._params, undefined, sortByAttribute, queryParams);
	}

	getFilteredList(strSearch: string, pagination: Pagination, mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute?: string, queryParams?: string): Observable<any> {
		return this.httpClientService.getList(this._microService, this._endPoint, strSearch, pagination, this._params, mapFilter, sortByAttribute, queryParams);
	}

	getHistoricList(idQuery: string, fromDate, toDate, userIdArray: any[], pagination: Pagination) {
		return this.httpClientService.getHistoricList(this._microService, this._endPoint, idQuery, fromDate, toDate, userIdArray, pagination);
	}

	post(object: any, complement?: string) {
		return this.httpClientService.post(this._microService, this._endPoint, object, complement);
	}

	delete(id: number) {
		return this.httpClientService.delete(this._microService, this._endPoint, id);
	}

	deleteWithParams(params: string) {
		return this.httpClientService.deleteWithParams(this._microService, this._endPoint, params);
	}

	put(object: any) {
		return this.httpClientService.put(this._microService, this._endPoint, object);
	}

	patch(id: number, object: any) {
		return this.httpClientService.patch(this._microService, this._endPoint, id, object);
	}

	downloadFile(params: HttpParams): Observable<any> {
		return this.httpClientService.downloadFile(this._microService, params);
	}

	uploadFile(file: FormData, params: HttpParams): Observable<any> {
		return this.httpClientService.uploadFile(this._microService, file, params);
	}

	deleteFile(params: HttpParams): Observable<any> {
		return this.httpClientService.deleteFile(this._microService, params);
	}


	getListXls(queryParams?: string): Observable<any> {
		return this.httpClientService.getListXLS(this._microService, this._endPoint, this._params, queryParams);

	}

	getFilteredListWithDate(strSearch: string, pagination: Pagination, mapFilter: Map<string, ObjectMultiFilter>, sortByAttribute?: string, queryParams?: string): Observable<any> {
		return this.httpClientService.getListWithoutCheckIfHasDate(this._microService, this._endPoint, strSearch, pagination, this._params, mapFilter, sortByAttribute, queryParams);
	}

}