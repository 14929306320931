import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { DataTableSelectedRows } from 'src/app/shared/model/data-table-selected-rows.model';
import localePt from '@angular/common/locales/pt';
import { Labels } from '../../../../internationalization/labels/labels';
import { Pagination } from '../../dto/pagination';
registerLocaleData(localePt);

@Component({
	selector: 'app-multiple-pages-selection',
	templateUrl: './multiple-pages-selection.component.html',
	styleUrls: ['./multiple-pages-selection.component.scss']
})
export class MultiplePagesSelectionComponent implements OnInit {
	@Input() isMultiplePagesSelection: boolean;
	@Input() _selectAll: boolean;
	@Input() arrayData: any[];
	@Input() pagination: Pagination;
	@Input() selectAll: (args: any) => void;
	@Input() selectAllOnPage: (args: any) => void;
	@Input() deselectAll: (args: any) => void;
	@Input() someComplete: (args: any) => boolean;
	@Input() onRowSelection: (args: any) => boolean;
	@Input() disabledRow: boolean;

	@Output() _selectAllChange = new EventEmitter();
	@Output() _onSelectAll = new EventEmitter();
	@Output() arrayDataChange = new EventEmitter();
	@Output() selectedRows = new EventEmitter<DataTableSelectedRows<any>>();

	public _labels = Labels.getLabels();

	constructor() { }

	ngOnInit() {}

	openOptions(e) {
		if (e.target.classList.contains('mat-checkbox-inner-container')) return;

		const selector: HTMLElement = document.querySelector('.multiple-pages-selection .multiple-bubble');
		selector.style.display = (selector.style.display === 'block')
			? 'none'
			: 'block';
	}

	onSelectAll(){
		this._onSelectAll.emit(true)
	}

	onDeSelectAll(){
		this._onSelectAll.emit(false)
	}
	
	isSelectAllOnPageDisabled() {
		const selected = this.arrayData.filter(data => data.rowSelection === true);

		const isSelectAll = () => document.querySelector('.multiple-pages-selection .select-all');

		return (!this.arrayData || this.arrayData.length == 0 || ((selected.length === this.pagination.totalData) && (selected.length > this.pagination.pageSize))) || isSelectAll();
	}
}
