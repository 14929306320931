import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnDestroy, Inject} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CoreUtil } from '../../core-util';
import { Pagination } from '../../dto/pagination';
import { ObjectMultiFilter } from '../../dto/object-multi-filter.dto';
import { AbstractService } from '../../abstract/abstract-service';
import { Response } from 'src/app/resource/dto/response';
import { Messages } from 'src/internationalization/messages/messages';
import { CoreUtil as util } from 'src/app/core/core-util';
import { TaxIdPipe } from '../../pipe/tax-id.pipe';
import { CpfCnpjPipe } from '../../pipe/cpf-cnpj.pipe';
import { ZipCodePipe } from '../../pipe/zip-code.pipe';
import { PhonePipe } from '../../pipe/phone.pipe';
import { BooleanPipe } from '../../pipe/boolean.pipe';
import { StringSplitPipe } from '../../pipe/string-split.pipe';
import { TimePipe } from '../../pipe/time.pipe';
import { DomainStatusPipe } from '../../pipe/domain-status.pipe';
import { DatePipe } from '../../pipe/date.pipe';
import { NumberDecimalPipe } from '../../pipe/number-decimal.pipe';
import { Labels } from 'src/internationalization/labels/labels';
import { Icons } from 'src/icons';
import { Colors } from 'src/colors';
import { EButtonDirective } from 'src/app/resource/enums/e-button-directive.enum';
import {ArrayHeaderGridListModel} from '../../../shared/model/array-header-grid-list.model';
import { AdhocDatePipe } from '../../pipe/adhoc-date.pipe';
import { negative_number_and_large_pipe } from '../../pipe/negative-number-and-large-pipe';
import { PercentLargePipe } from '../../pipe/percent-large-number-pipe.pipe';
import { NaPipe } from '../../pipe/n_a.pipe';
import { LargeNumberPipe } from '../../pipe/large-number-pipe.pipe';
import { SpacingSemicolonCommaPipe } from '../../pipe/spacing-semicolon-comma.pipe';
import {PrefixTaxIdListModalComponent} from '../../../resource/page/customer/corporate-group/customer-corporate-group/payment-condition/prefix-tax-id-list-modal/prefix-tax-id-list-modal.component';
import {MatDialog} from '@angular/material';
import {StringLimitPipe} from '../../pipe/string-limit.pipe';
import * as moment from 'moment/moment';

@Component({
	selector: 'app-grid-list',
	templateUrl: './grid-list.component.html',
	styleUrls: ['./grid-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
			state('expanded', style({ height: '*', visibility: 'visible' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class GridListComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() icon: string;
	@Input() statusBulletWithText: boolean;
	@Input() arrayHeader: ArrayHeaderGridListModel[];
	@Input() arrayData: any[];
	@Input() arrayAction: any[];
	@Input() arrayChildAction: any[];
	@Input() mapFilter: Map<string, ObjectMultiFilter>;
	@Input() mapFilterLength = [];
	@Input() exportsXls = false;
	@Input() exportsXlsDataTransformer;
	@Input() xlsHeader;
	@Input() expandedArrayHeader: any[]; // for situations where the xls'll be larger than the displayed grid
	@Input() xlsArray: any[]; // generate xls with different grid columns
	@Input() service: AbstractService<any>;
	@Input() getListParams: string;
	@Input() searchTitle: string;
	@Input() caracterPressedToSearch: number = 0;
	@Input() onlyTitle: false;
	@Input() isCard = true;
	@Input() noPaddingBottom = false;
	@Input() isAdd = true;
	@Input() isAddDisabled = false;
	@Input() isSearch = false;
	@Input() customIcon;
	@Input() applyShadow = false;
	@Input() height;
	@Input() pagination: Pagination;
	@Input() acordeonColumnList: string;
	@Input() accordionChildColumnList: string;
	@Input() acordeonShowFirstRow = true;
	@Input() openedAcordeonMap: Map<number, boolean>;
	@Input() readonly = false;
	@Input() rowOpacity;
	@Input() filter = '';
	@Input() filterAsc = true;
	@Input() clickable = false;
	@Input() allowSelection = false;
	@Input() scroll = true;
	@Input() searchFinished: boolean;
	@Input() searchText = '';
	@Input() gridInformation: string;
	@Input() showDetails = false;
	@Input() microService: string;
	@Input() toggleDisabled = false;
	@Input() displayFilter = true;
	@Input() xlsTitle: string = '';
	@Input() noRowsToShow: boolean;
	@Input() hideFilterButton: boolean = false;
	@Input() disabledXlsButton: boolean = false;
	@Input() scrollX: boolean = true;
	@Input() showEmptyMessage: boolean = false;
	@Input() showEmptyCustomMessage: string = '';
	@Input() xlsHasDateTime:boolean = false;

	@Output() paginationChange = new EventEmitter();
	@Output() globalSearch = new EventEmitter();
	@Output() add = new EventEmitter();
	@Output() clicked = new EventEmitter();
	@Output() customIconClicked = new EventEmitter();
	@Output() showDetailsChange = new EventEmitter();
	@Output() inputChanged = new EventEmitter();
	@Output() rowSelection = new EventEmitter();
	@Output() searchItem = new EventEmitter<{index: number, field: string}>();
	@Output() clearAllActivated = new EventEmitter<any>();

	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public _icons = Icons;
	public _colors = Colors.color;
	public _lastSearchText: string = '';
	public _displayedColumns = [];
	public _expandedElements: any[] = [];
	public _totalColumns = 0;
	public _displayFilters = false;
	public _tableHeight;
	public _tableWidth;
	public _isMapFilterChanged = false;
	public _selectAll = false;
	public _searchDisabled = false;
	public _displaySearchInput = false;
	public _actionWidth;
	public _actionColumnHeaderWidth: number;
	public _expandAll = false;
	public _eButtonDirective = EButtonDirective;
	public _actualPage: number;
	public _hasSubMenu: boolean = false;

	private _pipes: any;
	private _subscriptionList: any;

	constructor(
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.mapFilterChange();
		this.validateSearch();

		this._pipes = {
			taxId: new TaxIdPipe(),
			cpfCnpj: new CpfCnpjPipe(),
			zipCode: new ZipCodePipe(),
			phone: new PhonePipe(),
			boolean: new BooleanPipe(),
			stringSplit: new StringSplitPipe(),
			time: new TimePipe(),
			domainStatus: new DomainStatusPipe(),
			date: new DatePipe(),
			adhoc_date : new AdhocDatePipe(),
			numberDecimal: new NumberDecimalPipe(),
			largeNumber: new PercentLargePipe(),
			negativeNumber: new negative_number_and_large_pipe(),
			NaPipe: new NaPipe(),
			LargeNumberPipe: new LargeNumberPipe(),
			spacing_semicolon_comma: new SpacingSemicolonCommaPipe(),
			stringLimit: new StringLimitPipe()
		};

		this.checkMapFilterChanges();
		this.hasSubMenu();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.arrayHeader && changes.arrayHeader.currentValue) {
			this._displayedColumns = this.arrayHeader.map(a => a.key);
		}

		if (changes.searchText && !!changes.searchText.currentValue && !this._lastSearchText) {
			this._lastSearchText = changes.searchText.currentValue;
		}

		if (this.openedAcordeonMap) {
			this.expandMapped();
		}

		if (this.arrayAction && !this._displayedColumns.find(d => d == 'actions')) {

			if (this.allowSelection && !this._displayedColumns.find(d => d == 'rowSelection')) {
				this._displayedColumns.push('rowSelection');
			}

			if (this.arrayAction.length > 0) {
				this._displayedColumns.push('actions');
			}
		}

		if (changes.arrayData && changes.arrayData.currentValue) {
			if (this.arrayData !== changes.arrayData.currentValue) {
				this.arrayData = changes.arrayData.currentValue;
			}
			this.updateTableHeight();
		}

		if (changes.arrayAction || changes.arrayChildAction) {
			this._actionColumnHeaderWidth = this.actionColumnWidth();
		}
		if (this._displayedColumns) {
			this._totalColumns = this._displayedColumns.length;
		}
	}

	checkMapFilterChanges() {
		if (this.mapFilter) {
			// tslint:disable-next-line: no-unused-variable
			for (const [key, value] of this.mapFilter) {
				if (value.arrayData.length > 0 || value.arrayValue.length > 0 || value.allSelected) {
					this.mapFilterChange();



					return;
				}
			}
		}
	}

	private hasSubMenu() {
		this.arrayHeader.forEach(item => {
			if (item.subMenu) {
				this._hasSubMenu = true;
			}
		});
	}

	exportXls() {
		if (this.arrayData && this.arrayData.length > 0) {
			if (this.pagination && this.pagination.totalData > this.arrayData.length && this.service) {
				const subscription = this.service
					.getFilteredList(this.getListParams ? this.getListParams : this.searchText, undefined, this.mapFilter)
					.subscribe((response: Response<any>) => {
						this.doExportXls(response.data);
					});
				this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
			} else {
				this.doExportXls(this.arrayData);
			}
		}
	}

	doExportXls(array) {
		let xlsFormated = '';
		const header = this.expandedArrayHeader ? this.expandedArrayHeader : this.arrayHeader;
		const arrayList = this.xlsArray ? this.xlsArray : array;
		if(this.xlsHasDateTime) {
			xlsFormated =	`${this.xlsTitle.replace(/\s+/g, '')}_${moment.utc().local().format('YYYYMMDD_HH[h]mm')}`
		}
		const title = this.xlsHasDateTime ? xlsFormated : (this.xlsTitle == '' ? this.title : this.xlsTitle);

		CoreUtil.exportXls(arrayList, header, this.xlsHeader, this.exportsXlsDataTransformer, this.acordeonColumnList, title, this.accordionChildColumnList);
	}

	setFilter(acordeonChild, filter, doNotSort?) {
		if (doNotSort) return;

		if (!acordeonChild && this.arrayData && this.arrayData.length > 0) {
			if (filter != this.filter) {
				this.filterAsc = true;
				this.filter = filter;
			} else {
				this.filterAsc = !this.filterAsc;
			}
		}
	}

	getToolTip(colHeader, el, td) {
		if (td.offsetWidth < td.scrollWidth) {
			const toolTipText = !colHeader.viewDetails ?
				(el.viewDetails ?
					td.innerText :
					!colHeader.displayImages ?
						this.tooltipMask(this.splitProperty(el, colHeader.key), colHeader.pipe, colHeader.pipeParams) :
						(colHeader.multiValue ?
							this.splitMultiImageNames(colHeader, el) :
							this.splitImageName(colHeader, el)
						)
				)
				: '';

			return toolTipText;
		} else {
			if(colHeader.tooltipInContent){
				this.tooltipMask(this.splitProperty(el, colHeader.key), colHeader.pipe, colHeader.pipeParams)
			}
			return '';
		}
	}

	splitImageName(colHeader: any, el: any) {
		let toolTipText = '';
		if (el[colHeader.key]) {
			const stringsArray = el[colHeader.key].split('/');
			toolTipText = stringsArray[stringsArray.length - 1];
		}

		return toolTipText;
	}

	splitMultiImageNames(colHeader: any, el: any): string {
		if (colHeader.getToolTipFunction) {
			return colHeader.getToolTipFunction(colHeader, el);
		} else {
			let toolTipText = '';

			if (el[colHeader.key]) {
				const stringsArray = el[colHeader.key].split(colHeader.addressStringSeparator);

				for (let i = 0; i < stringsArray.length; i++) {
					const str = stringsArray[i];
					const namesArray = str.split('/');
					const fileName = namesArray[namesArray.length - 1];
					toolTipText += fileName.split('.')[0];

					if (i < stringsArray.length - 1) toolTipText += ', ';
				}
			}

			return toolTipText;
		}
	}

	splitProperty(obj: any, property: string) {
		return CoreUtil.getSplittedProperty(obj, property);
	}

	getTooltipMask(value, pipe, params, td) {
		if (td.offsetWidth < td.scrollWidth) {
			return this.tooltipMask(value, pipe, params);
		} else {
			return '';
		}
	}

	tooltipMask(value, pipe, params) {
		if (!pipe || (!value && value != 0)) {
			return value;
		}

		let ret = '';

		switch (pipe) {
			case 'taxId':
				ret = this._pipes.taxId.transform(value);
				break;
			case 'cpfCnpj':
				ret = this._pipes.cpfCnpj.transform(value);
				break;
			case 'zipCode':
				ret = this._pipes.zipCode.transform(value);
				break;
			case 'phone':
				ret = this._pipes.phone.transform(value, false);
				break;
			case 'mobile':
				ret = this._pipes.phone.transform(value, true);
				break;
			case 'boolean':
				ret = this._pipes.boolean.transform(value);
				break;
			case 'stringSplit':
				ret = this._pipes.stringSplit.transform(value, params);
				break;
			case 'stringLimit':
				ret = this._pipes.stringLimit.transform(value, params);
				break;
			case 'time':
				ret = this._pipes.time.transform(value);
				break;
			case 'domainStatus':
				ret = this._pipes.domainStatus.transform(value);
				break;
			case 'date':
				ret = this._pipes.date.transform(value);
				break;
			case 'numberDecimal':
				ret = this._pipes.numberDecimal.transform(value, params[0], params[1], params[2], params[3]);
				break;
			case 'adhoc_date':
				ret = this._pipes.adhoc_date.transform(value);
				break;
			case 'negative_and_large_pipe':
				ret = this._pipes.negative_number_and_large_pipe.transform(value);
				break;
			case 'percent_and_large_pipe':
				ret = this._pipes.PercentLargePipe.transform(value);
				break;
			case 'n_a':
				ret = this._pipes.NaPipe.transform(value);
				break;
			case 'large_number_pipe':
				ret = this._pipes.LargeNumberPipe.transform(value);
				break;
			case 'spacing_semicolon_comma':
				ret = this._pipes.spacing_semicolon_comma.transform(value);
				break;
		}

		return ret;
	}

	validateSearch() {
		if (this.caracterPressedToSearch > 0) {
			if (this.searchText.length >= this.caracterPressedToSearch) {
				this._searchDisabled = false;
			} else {
				this._searchDisabled = true;
			}
		} else {
			this._searchDisabled = false;
		}
	}

	public doSearchEnter() {
		if (this.searchText.length >= this.caracterPressedToSearch) {
			this.doSearch();
		}
	}

	getIndex(index: number) {
		this.searchItem.emit({index: index, field: ''});
	}

	public doSearch(dropdownListFilter = false, isPagination = false, index?) {
		if (!isPagination) {
			this.pagination.page = 0;
			this._actualPage = 0;
		}

		if (this._lastSearchText != this.searchText || dropdownListFilter) {
			this.searchItem.emit({index: index, field: ''});
			this.globalSearch.emit(this.searchText );
			this.updateTableHeight();
			this._lastSearchText = this.searchText;
		}
	}

	isMapFilterEmpty() {
		let count = 0;
		if (this.mapFilter) {
			// tslint:disable-next-line: no-unused-variable
			for (const [key, filter] of this.mapFilter) {
				count += this.isMapSelectFilter(filter) ? filter.filter.value.length : filter.arrayValue.length;
			}
		}

		return count === 0;
	}

	isMapSelectFilter(filter) {
		return filter.filter && filter.filter.value;
	}

	isAllSelected() {
		let allSelected = false;
		if (this.mapFilter) {
			// tslint:disable-next-line: no-unused-variable
			for (const [key, filter] of this.mapFilter) {
				if (filter.allSelected) {
					allSelected = true;
				}
			}
		}

		return allSelected;
	}

	public updateTableHeight() {
		this._tableHeight = this._tableHeight != undefined ? this._tableHeight + 1 : 0;
		this._tableWidth = this._tableWidth != undefined ? this._tableWidth + 1 : 0;
	}

	pageChanged(pagination) {
		this.pagination = pagination;

		this.paginationChange.emit(this.pagination);
		this.doSearch(true, true);

		this._expandedElements = [];
		this._expandAll = false;

		this.viewDetailsClick(true);
	}

	expand(el) {
		el.showAcordeon = !el.showAcordeon;

		const index = this._expandedElements.indexOf(el);

		if (this.openedAcordeonMap) {
			const pos = this.arrayData.indexOf(el);
			this.openedAcordeonMap.set(pos, el.showAcordeon);
		}

		if (index < 0) {
			this._expandedElements.push(el);
		} else {
			this._expandedElements.splice(index, 1);
			this._expandAll = false;
		}
	}

	expandAll() {
		if (this.arrayData && this.arrayData.length > 0) {
			let update = false;
			this._expandedElements = [];

			for (const data of this.arrayData) {
				data.showAcordeon = this._expandAll;
				const obj = this.splitProperty(data, this.acordeonColumnList);

				if (obj && obj.length > (this.acordeonShowFirstRow ? 1 : 0)) {
					this.expand(data);

					update = true;
				}
			}

			if (update) {
				this._expandAll = !this._expandAll;
			}

			this.updateTableHeight();
		}
	}

	expandMapped() {
		if (!this.openedAcordeonMap) return;

		if (this.arrayData && this.arrayData.length > 0) {
			for (let i = 0; i < this.arrayData.length; i++) {
				const elem = this.openedAcordeonMap.get(i);

				if (elem != undefined) {
					const data = this.arrayData[i];
					data.showAcordeon = elem;
					const obj = this.splitProperty(data, this.acordeonColumnList);

					if (obj && obj.length > (this.acordeonShowFirstRow ? 1 : 0)) {
						this.expand(data);
					}
				}
			}

			this.updateTableHeight();
		}
	}

	setWidth() {
		if (this.arrayHeader) {
			this.arrayHeader.forEach(a => a.width = 0);
		}
	}

	addEmit() {
		if (!this.readonly) {
			this.add.emit();
		}
	}

	actionClick(el, action) {
		if (action.readonly) {
			if (!action.readonly(el)) {
				action.function(el);
			}
		} else {
			action.function(el);
		}
	}

	removeDropdownValue(filterValue, i) {
		filterValue.arrayValue.splice(i, 1);
		this.searchItem.emit({index: -1, field: filterValue.placeholder})

		const array = [];

		for (const a of filterValue.arrayValue) {
			array.push(a);
		}

		filterValue.arrayValue = array;

		this.doSearch(true);
		this.mapFilterChange();
	}

	mapFilterChange(index?: number) {
		this._isMapFilterChanged = false;

		if (this.searchText) {
			this._isMapFilterChanged = true;
		} else {
			if (this.mapFilter) {
				this.mapFilter.forEach((value, key) => {
					if (!value.filter) {
						if (value.arrayValue && value.arrayValue.length > 0) {
							this._isMapFilterChanged = true;
						}

						if (value.allSelected) {
							this._isMapFilterChanged = true;
						}

						if (value.value) {
							this._isMapFilterChanged = true;
						}
					} else {
						if (value.filter.filter || value.filter.value) {
							this._isMapFilterChanged = true;
						}
					}
				});
			}
		}
	}

	clearAllFilters() {
		this.searchText = '';

		for (const filter of this.mapFilter) {
			const value = filter[1];

			if (!value.filter) {
				value.allSelected = false;
				value.arrayValue = [];
				value.value = undefined;
			} else {
				value.filter.filter = undefined;
				value.filter.value = undefined;
			}
		}

		this._isMapFilterChanged = false;
		this.clearAllActivated.emit();

		this.doSearch(true);
	}

	selectAll(event) {
		this.arrayData.forEach((value) => {
			value.rowSelection = event.checked;
		});
	}

	changeRowSelection(event) {
		if (!event.checked) {
			this._selectAll = false;
		} else {
			let isAllSelected = true;

			for (const data of this.arrayData) {
				if (!data.rowSelection) {
					isAllSelected = false;
					break;
				}
			}


			this._selectAll = isAllSelected;
		}
		this.rowSelection.emit(event);
	}

	searchGrid() {
		if ((util.isNotEmpty(this.searchText) || util.isNotEmpty(this._lastSearchText)) &&
			this.searchText != this._lastSearchText) {
			this.doSearch();
			this._lastSearchText = this.searchText;
		}

		if (!util.isNotEmpty(this.searchText) || !this._displaySearchInput) {
			this._displaySearchInput = !this._displaySearchInput;
		}
	}

	viewDetailsClick(page) {
		this.showDetails = page ? false : !this.showDetails;

		for (const data of this.arrayData) {
			data.viewDetails = this.showDetails;
		}

		this.showDetailsChange.emit(this.showDetails);
	}

	viewDetailsElementClick(el) {
		el.viewDetails = !el.viewDetails;
		this.showDetails = false;

		for (const data of this.arrayData) {
			if (data.viewDetails) {
				this.showDetails = true;
				break;
			}
		}

		this.showDetailsChange.emit(this.showDetails);
	}

	inputChangeEvent(inputType, el, colHeader, index, event) {
		let splittedPath = [];
		let property = '';
		if (colHeader.acordeonChild && this.acordeonColumnList) {
			// if the input in a acordeon child, the method will iterate over the object
			// using the 'path' described on colHeader.acordeonChild. It iterates recursivelly
			// using Array().reduce(). The last part of colHeader.acordeonChild is used
			// as the property key of the object, used to set it's value.
			// const row = this.splitProperty(el, this.acordeonColumnList)[index]; // Unused code found 19/03/2021
			const splittedAcordeonChild = colHeader.acordeonChild.split('.');
			splittedPath = splittedAcordeonChild.slice(0, -1);
			property = splittedAcordeonChild[splittedAcordeonChild.length - 1];

			splittedPath.reduce(
				(acc, elem) => (acc[elem]),
				el[this.acordeonColumnList][index]
			)[property] = this.getInputValue(inputType, event, el, colHeader.acordeonChild);
		} else if (el && colHeader.key) {
			// if the input is in a row, the. It iterates recursivelly
			// using Array().reduce(). The last part of colHeader.acordeonChild is used
			// as the property key of the object, used to set it's value.
			const splittedKey = colHeader.key.split('.');
			splittedPath = splittedKey.slice(0, -1);
			property = splittedKey[splittedKey.length - 1];

			splittedPath.reduce(
				(acc, elem) => (acc[elem]),
				el
			)[property] = this.getInputValue(inputType, event, el, colHeader.key);
		}

		if (this.inputChanged) {
			this.inputChanged.emit({ inputType, el, colHeader, index, event });
		}
	}

	getInputValue(inputType: string, event: any, obj, key): any {
		// Please update this method for each new inputType used on GridList
		switch (inputType) {
			case 'toggle':
				// return event.checked ? 'A' : 'I'; // TODO: Sprint13 - need more tests
				if (typeof this.splitProperty(obj, key) === 'string') {
					return event.checked ? 'A' : 'I';
				} else {
					return event.checked;
				}
			case 'input':
				return event.target.value;
			default:
				return event;
		}
	}

	toogleCheckVerifier(el, key) {
		let result = false;
		if (this.splitProperty(el, key) != undefined) {
			result = this.splitProperty(el, key) || this.splitProperty(el, key) == 'A';
		}

		return result;
	}

	getImagesSrcArray(colHeader, el) {
		let splittedString = '';

		if (el[colHeader.stringName]) {
			const stringToSplit = el[colHeader.stringName];
			splittedString = stringToSplit.split(colHeader.addressStringSeparator);
		}

		return splittedString;
	}

	actionColumnWidth() {
		const defaultValue = 100;
		const multiplicationFactor = 35;
		if (this.arrayChildAction && this.arrayChildAction.length > 1) return this.arrayChildAction.length * multiplicationFactor;
		if (this.arrayAction && this.arrayAction.length > 1) return this.arrayAction.length * multiplicationFactor;

		return defaultValue;
	}

	ngOnDestroy() {
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}

	openDialogClick(el, colHeader: ArrayHeaderGridListModel) {
		const dialogRef = this.dialog.open(PrefixTaxIdListModalComponent, {
			disableClose: true,
			width: '23rem',
			maxHeight: '16rem',
			height: '16rem',
			data: el
		});
	}


	simpleAndLimitedByCharacters(value, length) {
		if (!value) return ''
		return value.toString().length != 0 && value.toString().length <= length[0] ? '' : value;
	}

	getHeightForTable() {
		if(this.noRowsToShow){
			return '100px'
		}
		if(this.height && !this.noRowsToShow )  {
		if(this._tableHeight < this.height && this._tableHeight > 1 ) return this._tableHeight.toString().concat('px');
		else return this.height.toString().concat('px');
		} else return  'unset'
	}
}