import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArraySortPipe } from './array-sort.pipe';
import { PicklistPipe } from './picklist.pipe';
import { TaxIdPipe } from './tax-id.pipe';
import { DatePipe } from './date.pipe';
import { PhonePipe } from './phone.pipe';
import { ZipCodePipe } from './zip-code.pipe';
import { DropdownListPipe } from './dropdown-list.pipe';
import { BooleanPipe } from './boolean.pipe';
import { StringLimitPipe } from './string-limit.pipe';
import { StringSplitPipe } from './string-split.pipe';
import { TimePipe } from './time.pipe';
import { DomainStatusPipe } from './domain-status.pipe';
import { ExcludingArrayPipe } from './excluding-array.pipe';
import { CpfCnpjPipe } from './cpf-cnpj.pipe';
import { SelectAllPipe } from './select-all.pipe';
import { NumberDecimalPipe } from './number-decimal.pipe';
import { NumberFormaterPipe } from './number-formater.pipe';
import {EnumPipe} from './enum.pipe';
import { AdhocDatePipe } from './adhoc-date.pipe';
import { DirectionPipe } from './direction.pipe';
import { PercentLargePipe } from './percent-large-number-pipe.pipe';
import { negative_number_and_large_pipe} from './negative-number-and-large-pipe';
import { NaPipe } from './n_a.pipe';
import {NgxMaskModule} from 'ngx-mask';
import { LargeNumberPipe } from './large-number-pipe.pipe';
import { SpacingSemicolonCommaPipe } from './spacing-semicolon-comma.pipe';
import { extense_and_negative_number_pipe } from './extense-and-negative-number-pipe';
import { SnakeToPascalPipe } from './snake-to-pascal.pipe';
import { cutoffTypeDescription } from './cutoff-type-description.pipe';
import { TimeDoubledotPipe } from './time-doubledot.pipe';
import {CapitalizeFirstLetter} from './capitalize-first-letter.pipe';


@NgModule({
	declarations: [
		ArraySortPipe,
		PicklistPipe,
		TaxIdPipe,
		DatePipe,
		AdhocDatePipe,
		PhonePipe,
		ZipCodePipe,
		DropdownListPipe,
		BooleanPipe,
		EnumPipe,
		StringLimitPipe,
		StringSplitPipe,
		TimePipe,
		DomainStatusPipe,
		ExcludingArrayPipe,
		CpfCnpjPipe,
		SelectAllPipe,
		NumberDecimalPipe,
		NumberFormaterPipe,
		DirectionPipe,
		PercentLargePipe,
		negative_number_and_large_pipe,
		NaPipe,
		LargeNumberPipe,
		SpacingSemicolonCommaPipe,
		extense_and_negative_number_pipe,
		SnakeToPascalPipe,
		cutoffTypeDescription,
  TimeDoubledotPipe,
		CapitalizeFirstLetter

	],
	imports: [
		CommonModule,
		NgxMaskModule
	],
	exports: [
		ArraySortPipe,
		PicklistPipe,
		TaxIdPipe,
		DatePipe,
		AdhocDatePipe,
		PhonePipe,
		ZipCodePipe,
		DropdownListPipe,
		BooleanPipe,
		EnumPipe,
		StringLimitPipe,
		StringSplitPipe,
		TimePipe,
		DomainStatusPipe,
		ExcludingArrayPipe,
		CpfCnpjPipe,
		SelectAllPipe,
		NumberDecimalPipe,
		NumberFormaterPipe,
		DirectionPipe,
		PercentLargePipe,
		negative_number_and_large_pipe,
		NaPipe,
		NgxMaskModule,
		LargeNumberPipe,
		SpacingSemicolonCommaPipe,
		extense_and_negative_number_pipe,
		SnakeToPascalPipe,
		cutoffTypeDescription,
		TimeDoubledotPipe,
		CapitalizeFirstLetter
	],
})
export class PipeModule { }